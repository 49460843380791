.changelog {
    .modal-body {
        max-height: 60vh;
    }

    .log {
        padding: box-model(m, 0);

        header {
            margin-bottom: box-model(xs);
        }

        h1 {
            @include font-size(base);
            @include line-height(base);
            margin-bottom: 0;
        }
    }

    .opposite {
        @include font-size(s);
        color: color(base-500);
    }
}
