.registrations.chart-widget {

    .chart {
        display: flex;
        flex-wrap: wrap;
        gap: box-model(m);
    }

    .double-stat {
        flex: 0 0 calc(25% - #{box-model(s)});
        box-sizing: border-box;
    }
}

.pax-request-actions {
    p:last-child {
        margin-bottom: 0;
    }
}
