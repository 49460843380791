.checkboxes-expandable {
    .fields-list {
        &.full {
            .options-list {
                max-height: calc(box-model(h) * 3);
            }

            .full-list-toggler {
                i {
                    transform: rotate(180deg);
                }
            }
        }

        > .form-group {
            margin-bottom: box-model(xs);
        }

        .options-list {
            padding: box-model(xs);
            overflow-y: auto;
            max-height: box-model(h);
            border: 1px solid color(base-100);
        }
    }

    .full-list-toggler {
        display: block;
        text-align: center;
        padding: box-model(xs);

        i {
            @include icon-size(xs);
            vertical-align: middle;
        }
    }
}
