/*******************************************
* SPINNER : LOADING DATA PROGRESS BAR     *
*******************************************/

@keyframes jumpingSpotletDots {
    0% {
        top: 0;
    }
    100% {
        color: #ddd;
    }
}

@keyframes fadeInThrob {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// // //

.spotlet-loading-data-throbber {
    text-align: center;
    margin: auto auto;
    padding: 30px 50px;
    width: 200px;
    z-index: 2000;
    font-size: 80px;
    line-height: 10px;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
    font-family: Georgia;
    transition: opacity 0.15s linear;
    animation-duration: 1000ms;
    animation-name: fadeInThrob;
}

.spotlet-loading-data-throbber.active {
    z-index: 10;
    visibility: visible;
    top: 50px;
}

.spotlet-loading-data-throbber span {
    padding: 1px;
    position: relative;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-name: jumpingSpotletDots;
}

.spotlet-loading-data-throbber span:nth-child(1) {
    color: $spotme-grey;
    animation-delay: 0ms;
}

.spotlet-loading-data-throbber span:nth-child(2) {
    color: $spotme-orange;
    animation-delay: 200ms;
}

.spotlet-loading-data-throbber span:nth-child(3) {
    color: $spotme-yellow;
    animation-delay: 400ms;
}

.spotlet-loading-data-throbber span:nth-child(4) {
    color: $spotme-green;
    animation-delay: 600ms;
}

.spotlet-loading-data-throbber span:nth-child(5) {
    color: $spotme-cyan;
    animation-delay: 800ms;
}

// // //

.modal .modal-header .spotlet-loading-data-throbber {
    width: 60px;
    padding: 0px 12px;
    font-size: 32px;
    font-size: 2rem;
}
