.file-picker {
    @extend %upload-selector;

    .dropbox {
        position: relative;
        cursor: pointer;
        min-height: $input-height;
        box-sizing: border-box;
        padding: box-model(xxs);
    }

    .input-file {
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        min-height: $input-height;;
        cursor: pointer;
    }

    &.active {
        border-color: color(primary-500);
        background-color: color(primary-050);
    }
}

.image-picker {
    &.form-group {
        .images {
            margin-bottom: 0;
        }
    }

    .control-hint {
        margin-bottom: box-model(xs);
    }

    .file-uploader {
        padding: 0;
    }

    .images {
        margin-bottom: box-model(m);
        grid-template-columns: repeat(auto-fit, minmax($list-tile-width, 1fr));

        &:empty {
            margin-bottom: 0;
        }
    }

    .image-preview {
        picture {
            cursor: zoom-in;
        }
    }

}

.thumbnail-picker {
    .image-preview picture {
        border-radius: $base-border-radius;
        border: 1px solid color(base-300);
        overflow: hidden;
    }
}

.external-picker {
    margin-bottom: box-model(l);
    display: flex;
    gap: box-model(xs);
    flex-direction: column;

    &:last-child {
        margin-bottom: 0;
    }

    .form-group {
        margin-bottom: 0;
        flex: 1 1 auto;
    }

    .option {
        display: flex;
        gap: box-model(xs);
        min-width: 0;

        .icon {
            flex: 0 1 icon-size(xxs);

            img {
                vertical-align: middle;
            }
        }

        .title, .subtitle {
            @include ellipsis;
            @include line-height(xxs);
        }

        .subtitle {
            @include font-size(s);
            color: color(base-500);
            word-wrap: break-word;
            white-space: normal;
            overflow: visible;

            &.ext {
                margin-left: box-model(xs);
            }

            &.warning {
                color: color(error-300)
            }
        }
    }

    .documents {
        .option {
            flex-direction: column;
            gap: 0;
        }
    }

    .doc {
        display: flex;
        border-bottom: 1px solid color(base-300);
        padding: box-model(xs, 0);
        align-items: flex-end;
        gap: box-model(xs);

        &:last-child {
            border-bottom: 0;
        }

        &.sortable {
            cursor: row-resize;
            overflow-wrap: break-word;
        }

        > * {
            flex: 1 1 50%;
        }

        .ext-picker-details {
            flex: 1 0 50%;
            min-width: 0;
            display: flex;
            gap: box-model(s);
            align-items: center;
        }

        .details {
            @include ellipsis;
            flex: 1 0 50%;
            min-width: 0;
            display: flex;
            align-items: center;

            > i, > .badge {
                flex-shrink: 0;
            }
        }

        .actions {
            flex: 0 0 auto;
            align-self: center;
        }

        a.remove {
            float: right;
            color: color(base-500);
        }

        .badge {
            margin-left: 0;
            margin-right: box-model(xs);
        }

        .details i,
        .actions i {
            @include icon-size(xs);
            vertical-align: middle;

            &.type, &.drag {
                margin-right: box-model(xs);
            }

            &.drag {
                opacity: .5;
            }
        }

        .doc-extras {
            flex: 1 1 50%;
            .participant-availability {
                display: flex;
                justify-content: flex-end;
                gap: box-model(m);

                span {
                    &.available {
                        color: color(success-500);
                    }

                    &.not-available {
                        color: color(error-500);
                    }

                    &.awaiting {
                        color: color(base-500);
                    }
                }
            }
        }
    }

    .image-preview {
        line-height: 0;

        picture {
            display: inline-block;
            line-height: 1;
            font-size: 0;
            border-radius: $base-border-radius;
            overflow: hidden;
            border: 1px solid color(base-500);
        }

        img {
            height: box-model(xs) * 10;
        }
    }
}

.any-external-picker {
    .external {
        display: flex;
        gap: box-model(xs);
        margin-bottom: box-model(m);
        align-items: center;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .type-selection {
        flex: 1 1 20%;
        margin-bottom: 0;
    }

    .form-groups {
        flex: 1;
    }

    .add-ext {
        margin-top: box-model(m);
    }
}

.vtt-picker {
    .modal-body {
        padding: 0 box-model(xxl);
        padding-bottom: box-model(l);

        .vtt-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .files-uploaded, .file-picker {
                width: 100%;
            }

            label {
                cursor: inherit;
            }

            i {
                cursor: pointer;
                padding: box-model(xxs) box-model(xs);
                background-color: color(base-300);
                border-radius: $base-border-radius;
            }

            .remove-link {
                @include font-size(s);
                @include font-weight(semibold);
                @include line-height(xs);
                color: color(error-300);
                margin-bottom: box-model(xs);
                cursor: pointer;
            }

            .files-uploaded {
                @extend %upload-selector;
                padding: box-model(xxs);
            }
        }
    }

    .modal-footer {
        display: flex;
        justify-content: space-between;

        .add-languages-btn {
            display: flex;
            align-items: center;
            margin: 0;

            i {
                margin-right: 0;
                margin-left: box-model(xs);
            }
        }
    }
}

.content-page-picker {
    .form-scheduler {
        margin-top: box-model(-l) + box-model(-xxs); // border radius of the form-group
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        padding-top: box-model(m) + box-model(xxs);

        .btn {
            margin-bottom: 0;
        }
    }
}
