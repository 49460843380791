//
// Layouts
// Fixed-width and fluid (with sidebar) layouts
// --------------------------------------------

// Container (centered, fixed-width layouts)
.container {
    @include clearfix();
    margin-left: auto;
    margin-right: auto;
}

// Fluid layouts (left aligned, with sidebar, min- & max-width content)
.container-fluid {
    @include clearfix();
    padding-left: $gridGutterWidth;
    padding-right: $gridGutterWidth;
}
