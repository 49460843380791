.checklist {
    position: fixed;
    z-index: z-index(checklist);
    top: 0;
    right: $checklist-side-margin;
    width: $checklist-width;
    border-bottom-left-radius: $base-border-radius;
    border-bottom-right-radius: $base-border-radius;
    box-shadow: $checklist-shadow;
    overflow: hidden;
    border: 1px solid color(base-300);
    border-top: 0;

    &.expanded {
        .counters {
            top: nth($checklist-expanded-position, 1);
            right: nth($checklist-expanded-position, 2);
        }

        .progress-ring {
            opacity: 0;
        }

        section {
            max-height: $checklist-max-height;
        }

        i.ic-chevron-up {
            opacity: 1;
        }

        .completion {
            max-height: 40px;
        }

        .details {
            animation-name: appear;
        }
    }

    &.expanding {
        .details {
            animation-delay: .25s;
        }
    }

    &.animating {
        section {
            overflow: hidden;
        }
    }

    header {
        background-color: color(alt-1-300);
        color: color(base-000);
        padding: box-model(xs, m);
    }

    .progress-ring {
        position: absolute;
        top: box-model(xs);
        right: box-model(xs);
        opacity: 1;
        transition: $base-opacity-transition;
    }

    i.ic-chevron-up {
        font-size: icon-size(xs);
        float: right;
        transition: $base-opacity-transition;
        opacity: 0;
    }

    .title {
        @include font-weight(semibold);
    }

    .counters {
        @include font-size(s);
        position: absolute;
        top: nth($checklist-collapsed-position, 1);
        right: nth($checklist-collapsed-position, 2);
        transition: $checklist-counters-transition;
    }

    .handler {
        cursor: pointer;
    }

    .completion {
        overflow: hidden;
        max-height: 0;
        transition: $checklist-details-transition;
    }

    .subtitle {
        @include font-size(s);
        @include line-height(xxs);
        display: block;
        padding-top: box-model(xxs);
    }

    .details {
        opacity: 0;
        animation-name: fade;
        animation-duration: .15s;
        animation-fill-mode: forwards;
        animation-timing-function: $checklist-animation-timing-function;
    }

    section {
        background-color: color(base-000);
        max-height: 0;
        transition: $checklist-details-transition;
        overflow: auto;
    }

    ul.todo-list {
        list-style-type: none;
        margin: 0;
        padding: box-model(s);

        li {
            padding: box-model(xxs);
            padding-left:
                box-model(xxs) +
                icon-size(xs) + 6 +
                box-model(xxs);

            padding-right:
                box-model(xxs) +
                icon-size(s) +
                box-model(xxs);

            margin-bottom: box-model(s);
            border-radius: $base-border-radius;
            position: relative;
            transition: $base-background-color-transition;

            &:last-of-type {
                margin-bottom: 0;
            }

            &.has-action {
                cursor: pointer;

                &:hover {
                    background-color: color(base-100);

                    .go-suggestion {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .checkmark {
        position: absolute;
        margin-left:
            (
                icon-size(xs) + 6 +
                box-model(xxs)
            ) * -1;
    }

    i.ic-check-square {
        color: color(alt-1-300);
        font-size: icon-size(xs) + 6; // icon specific
    }

    .unchecked {
        display: block;
        width: icon-size(xs) - 3;
        height: icon-size(xs) - 3;
        border: 2px solid color(base-500);
        border-radius: $base-border-radius;
        margin-left: 1px; // icon specific
    }

    .go-suggestion {
        background-color: color(alt-1-300);
        color: color(base-000);
        float: right;
        display: flex;
        align-items: center;
        justify-content: center;
        height: icon-size(s);
        width: icon-size(s);
        border-radius: 100%;
        text-align: center;
        opacity: 0;
        transition: $base-opacity-transition;
        margin-right:
            (
                icon-size(s) +
                box-model(xxs)
            ) * -1;
    }

    .description {
        color: color(base-500);
    }
}
