.counter-stat-card {
    @extend %card-with-borders;
    padding: box-model(m);
    width: box-model(xs) * 20;
    box-sizing: border-box;

    .counter {
        @include font-size(xxm);
        @include font-weight(semibold);
    }

    .icon, .count {
        display: inline-block;
    }

    .icon {
        @include font-size(xm);

        vertical-align: middle;
        margin-right: box-model(xs);
    }

    .label {
        color: color(base-600);
    }
}
