%common-toolbar {
    background-color: color(base-000);
    position: fixed;
    left: $main-menu-width;
    right: 0;
    bottom: 0;
    transition: $common-toolbar-menu-collapse-transition;
    z-index: z-index(common-toolbar);

    .buttons-container {
        @extend %content-wrapper;

        min-width: breakpoint(xs);
    }

    .buttons {
        @include clearfix;

        border-top: 1px solid color(base-300);
        padding-top: box-model(m);
        padding-bottom: box-model(m);
        display: flex;
        text-align: right;
        justify-content: flex-end;

        ng-transclude, .contextual {
            flex: 1 1 auto;
            text-align: left;
            display: inline-block;
        }

        .new-spinner {
            display: none; // TODO: remove this in phase 3
        }
    }

    .custom-action {
        display: inline-block;
        margin: box-model(0, xs);
    }

    .contextual {
        .custom-action:first-child {
            margin-left: 0;
        }
    }

    .alert {
        @include line-height(xm);
        padding: box-model(0, m);
        margin: box-model(0, s);
    }
}

%default-common-toolbar-menu-minified {
    left: $main-menu-minimized-width;
}

.no-menu {
    .bs-common-toolbar, .secondary-page-toolbar {
        left: 0;
    }
}

.secondary-page-toolbar,
.bs-common-toolbar {
    @extend %common-toolbar;
}
