// Variables.less
// Variables to customize the look and feel of Bootstrap
// -----------------------------------------------------



// GLOBAL VALUES
// --------------------------------------------------

// Links
$linkColor:             #0059B3;
$linkColorAlt:          #004285;
$linkColorHover:        darken($linkColor, 10%);
$linkColorLighter:      lighten($linkColorAlt, 6%);

// Grays
$black:                 #000;
$grayDarker:            #222;
$grayDark:              #333;
$gray:                  #555;
$grayLight:             #999;
$grayLighter:           #eee;
$white:                 #fff;

// Accent colors
$blue:                  #049cdb;
$blueDark:              #0064cd;
$green:                 #46a546;
$red:                   #9d261d;
$yellow:                #ffc40d;
$orange:                #f89406;
$pink:                  #c3325f;
$purple:                #7a43b6;

// Typography
$baseFontSize:          13px;
$baseFontFamily:        "Helvetica Neue", Helvetica, Arial, sans-serif;
$baseLineHeight:        18px;
$textColor:             $grayDark;

// Buttons
$primaryButtonBackground:    $linkColor;



// COMPONENT VARIABLES
// --------------------------------------------------

// Z-index master list
// Used for a bird's eye view of components dependent on the z-axis
// Try to avoid customizing these :)
$zindexDropdown:          1000;
$zindexPopover:           1010;
$zindexTooltip:           1020;
$zindexFixedNavbar:       1030;
$zindexModalBackdrop:     1040;
$zindexModal:             1050;

// Sprite icons path
$iconSpritePath:          "";
$iconWhiteSpritePath:     "";

// Input placeholder text color
$placeholderText:         $grayLight;

// Hr border color
$hrBorder:                $grayLighter;

// Navbar
$navbarHeight:                    40px;

$navbarBackgroundHighlight:       #f5f5f5;
$navbarBackground:                #efefef;

$navbarText:                      #777;
$navbarLinkColor:                 #777;
$navbarLinkColorHover:            $grayDark;
$navbarLinkColorActive:           $gray;
$navbarLinkBackgroundHover:       transparent;
$navbarLinkBackgroundActive:      darken($navbarBackground, 5%);

// Form states and alerts
$warningText:             #c09853;
$warningBackground:       #fcf8e3;
$warningBorder:           darken(adjust-hue($warningBackground, -10), 3%);

$errorText:               #b94a48;
$errorBackground:         #f2dede;
$errorBorder:             darken(adjust-hue($errorBackground, -10), 3%);

$successText:             #468847;
$successBackground:       #dff0d8;
$successBorder:           darken(adjust-hue($successBackground, -10), 5%);

$infoText:                #3a87ad;
$infoBackground:          #d9edf7;
$infoBorder:              darken(adjust-hue($infoBackground, -10), 7%);



// GRID
// --------------------------------------------------

// Default 940px grid
$gridColumns:             $grid-columns;
$gridColumnWidth:         60px;
$gridGutterWidth:         $base-gutter;
$gridRowWidth:            ($gridColumns * $gridColumnWidth) + ($gridGutterWidth * ($gridColumns - 1));

// Fluid grid
$fluidGridGutterWidth:    $fluid-grid-gutter-width;
$fluidGridColumnWidth:    $fluid-grid-column-width;
