.minified-menu {
    @extend %default-main-menu-minified;

    .bs-common-toolbar, .secondary-page-toolbar {
        @extend %default-common-toolbar-menu-minified;
    }

    .loader {
        .loading {
            left: calc(50% + #{math.div($main-menu-minimized-width, 2)});
        }
    }
}

.modal-open {
    overflow: hidden;
}

.maximized {
    #intercom-container {
        display: none;
    }
}
