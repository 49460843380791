.badge-printing-settings {
    .panels {
        margin-bottom: box-model(xl);
    }

    .collapsed {
        .title-and-actions {
            border-bottom-color: color(base-300);
            border-radius: $large-border-radius;
        }

        .toggler {
            transform: rotate(180deg);
        }

        .config {
            overflow: hidden;
            padding-top: 0;
            padding-bottom: 0;
            opacity: 0;
            border-top-width: 0;
            border-bottom-width: 0;
            height: 0;
        }
    }
}

.onsite-scanning-settings {
    .view-wrapper > .actions {
        text-align: right;
        margin-bottom: box-model(m);
    }

    .zpl-code {
        &.collapsed {
            .title-and-actions {
                border-bottom-color: color(base-300);
            }

            .title-and-actions, .input-wrapper {
                border-radius: $large-border-radius;
            }

            .toggler {
                transform: rotate(180deg);
            }

            .config {
                overflow: hidden;
                padding-top: 0;
                padding-bottom: 0;
                opacity: 0;
                border-top-width: 0;
                border-bottom-width: 0;
                height: 0;
            }
        }

        &:focus-within {
            .title-and-actions, .config {
                border-color: color(primary-300);
            }

            .title-and-actions {
                border-bottom-color: transparent;
            }
        }

        span.title {
            > i {
                vertical-align: middle;
                margin-right: box-model(xs);
            }

            &.empty {
                opacity: .6;
            }
        }
    }

    .title-and-actions {
        border: 1px solid color(base-300);
        border-bottom-color: transparent;
        border-radius: $large-border-radius $large-border-radius 0px 0px;
        padding: box-model(s, xs, s, s);
        transition: border-radius .2s linear;
        box-sizing: border-box;

        &.editing {
            padding: 0;
            border: 0;
        }

        &:not(.editing) {
            .actions {
                float: right;
                margin-top: box-model(-xs);
            }
        }

        .title {
            @include ellipsis;
            display: inline-block;
            max-width: 75%;
            vertical-align: middle;
            margin-top: box-model(-xxxs);
        }

        .toggler, .dropdown {
            vertical-align: middle;
        }

        .toggler {
            @include font-size(xxm);
            @include line-height(xxm);

            height: box-model(xxl);
            width: box-model(xxl);
            display: inline-block;
            text-align: center;
            transition: transform .2s linear;

            i {
                line-height: box-model(xl); // icon specific
                vertical-align: middle;
            }
        }

        .input-wrapper {
            border-radius: $large-border-radius $large-border-radius 0px 0px;
            padding-left: box-model(s);

            input {
                @include font-weight(semibold);
                margin-right: box-model(xs);
            }
        }

        span.title {
            @include font-weight(semibold);
        }

        + .headers {
            border-radius: 0;
        }
    }

    .config {
        border: 1px solid color(base-300);
        border-top: 0;
        border-radius: 0 0 $large-border-radius $large-border-radius;
        box-sizing: border-box;
        padding: box-model(s);
        margin-bottom: box-model(l);

        transition:
            opacity .2s linear,
            padding .2s linear,
            border-top-color 2s linear;
    }
}
