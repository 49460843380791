// This namespace is needed only to make
// the CSS rule weight more agressive
body.has-vue {
    .VueCarousel {
        margin-bottom: $base-gutter;
    }

    .VueCarousel-wrapper {
        width: 75%;
        margin: 0 auto;
        font-size: 0;
        line-height: 0;
        border-radius: $base-border-radius;
    }

    .VueCarousel-navigation-button {
        bottom: 0;
        font-family: '#{$icons-font-family}' !important;
        font-size: 0;
        opacity: .5;
        top: 0;
        transform: none;
        transition: $carousel-buttons-opacity-transition;
        width: 12.5%;

        &:focus {
            outline: none;
        }

        &:hover {
            opacity: 1;
        }

        &.VueCarousel-navigation-next {
            @include icon(ic-chevron-right);
        }

        &.VueCarousel-navigation-prev {
            @include icon(ic-chevron-left);

            // TODO: remove once we migrate to the new iconset
            &::before {
                font-size: 22px;
            }
        }

        &::before {
            @include font-size(xl);
        }
    }

    .VueCarousel-slide {
        border-radius: $base-border-radius;
        overflow: hidden;
    }
}

.no-flex-supported {
    .VueCarousel-inner {
        display: block;
        white-space: nowrap;
    }

    .VueCarousel-slide {
        display: inline-block;

        img {
            max-height: $carousel-main-media-max-height;
        }
    }
}
