/**
 * LEGACY: CSS for the clipboard stuff
 * @see https://stackoverflow.com/questions/17527870/how-does-trello-access-the-users-clipboard
 */

#clipboard-container {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 0px;
    height: 0px;
    z-index: 100;
    display: none;
    opacity: 0;
}

#clipboard {
    width: 1px;
    height: 1px;
    padding: 0px;
}
