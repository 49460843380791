.theme-wizard {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px 0;

    &.invisible-until-custom-styles-loaded {
        visibility: visible;
    }

    .dropzone {
        width: 200px;
        height: 200px;
        border: 3px dashed #ddd;
        border-radius: 10px;
        margin: 0 auto 4px auto;

        text-align: center;

        .invitation-label {
            color: #666;
            padding: 80px 10px 10px 10px;
        }

        &.hover {
            border-color: #2EA1F0;
        }
    }

    .theme-editor-dropzone {
        .preview {
            display: none;
            max-width: 200px;
            margin: 0 auto;
        }

        &.file-loaded {
            .dropzone {
                display: none;
            }

            .preview {
                display: block;
            }
        }
    }

    .samples {
        text-align: center;

        .sample {
            transition: transform .3s ease, opacity .3s ease;
            border-radius: 4px;
            display: inline-block;
            margin: 10px;

            &.ng-enter {
                opacity: 0;
                transform: scale3d(0,0,0);

                &-stagger {
                    transition-delay: 0.05s;
                    transition-duration: 0s;
                }

                &-active {
                    opacity: 1;
                    transform: scale3d(1,1,1);
                }
            }

            &.ng-leave {
                opacity: 1;
                transition-duration: 0;
                transform: scale3d(1,1,1);
                display: none;

                &-active {
                    opacity: 0;
                    // transform: scale3d(0,0,0);
                }
            }
        }
    }

    .step {
        text-align: center;
        margin: 40px 0;

        &.first {
            margin-top: 0;
        }

        &-index {
            display: inline-block;
            color: #0051A3;
            border: 1px solid #3b5778;
            background-color: #f2f2f2;
            font-weight: bold;
            box-shadow: inset 0px 0px 1px 2px #fff;

            font-size: 19px;
            padding: 8px;
            line-height: 12px;
            border-radius: 50%;
            vertical-align: middle;
            margin-bottom: 10px;
        }
    }
}
