/* EXTRAS
 * -------------------------- */

/* Stacked and layered icon */
// .icon-stack();

/* Animated rotating icon */
.icon-spin {
    display: inline-block;
    animation: spin 2s infinite linear;
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(359deg);
    }
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
    }
}
@-o-keyframes spin {
    0% {
        -o-transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(359deg);
    }
}
@-ms-keyframes spin {
    0% {
        -ms-transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

/* Icon rotations and mirroring */
.icon-rotate-90:before {
    transform: rotate(90deg);
}

.icon-rotate-180:before {
    transform: rotate(180deg);
}

.icon-rotate-270:before {
    transform: rotate(270deg);
}

.icon-flip-horizontal:before {
    transform: scale(-1, 1);
}

.icon-flip-vertical:before {
    transform: scale(1, -1);
}
