//
// view partial styles
//

.floating-instance-selection-modal-view {
    h3.select-instance-for-event-header {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.dynamic-type-extension-solo-expander {
    border: 1px solid transparent;

    &.expanded {
        .dynamic-type-extension-solo-template {
            height: 128px;
            margin-bottom: 10px;
        }
    }

    &.expandable .teaser-picto-title {
        cursor: pointer;
        i.icon-expand-collapse-sign {
            visibility: visible;
        }
    }
    .teaser-picto-title {
        user-select: none;
    }
    i.icon-expand-collapse-sign {
        float: right;
        padding: 4px 0;
        visibility: hidden;
    }
    .dynamic-type-extension-solo-template {
        height: 0px;
        overflow-y: auto;
    }
}

.email-template-editor-view {
    position: relative;

    .ta-editor {
        height: 600px;
    }
    .ta-scroll-window.form-control {
        margin-top: 0;
    }

    textarea.raw-html-editor {
        font-family: monospace !important;
        height: 400px;
        width: 98.5%;
    }

    .btn-back-to-pax-list {
        position: absolute;
        bottom: 16px;
        right: 0px;
    }
}

.org-data-edit-view,
.org-profile-view {
    legend {
        font-size: 18px;
    }
}

.org-data-edit-view {
    .table-update-personal-data {
        margin-top: 0;
    }
}

.org-profile-view {
    .column-org-members-list {
        > fieldset,
        > fieldset > legend {
            margin-bottom: 9px;
        }
        ul {
            margin-left: 0;

            li:first-child {
                margin-top: 0;
            }
        }
    }

    .column-org-events-list {
        .table thead {
            border-top: 0;
        }

        .badge {
            margin-left: 5px;
        }
    }

    .pretty-list.users-list [class*='icon-'] {
        position: relative;
        font-size: 80%;
        top: 2px;
    }

    .promote-to-member {
        text-align: right;
    }
}

.event-package-management-view {
    .bundle-showcase .description-text {
        max-height: 50px;
        overflow-y: scroll;
    }
}

.event-sessions-list-view,
.event-participants-list-view,
.event-generic-list-with-actions-view {
    .field-box-info {
        position: relative;
        top: -56px;
    }
    .smart-table-sortable-cell {
        cursor: pointer;

        span {
            i {
                @include icon(ic-arrow-down-arrow-up);
                display: inline-block;
                margin: box-model(-xxxs, 0, 0, xs);
                vertical-align: middle;
            }
        }

        .sort-ascent i { @include icon(ic-chevron-up); }
        .sort-descent i { @include icon(ic-chevron-down); }
    }
    .smart-table-data-cell p > strong {
        font-size: font-size(base) + 1;
    }
}

.event-participants-list-view,
.event-generic-list-with-actions-view {
    .smart-table-data-cell span,
    .bs-navigate-to-pax-profile-cell {
        white-space: nowrap;
    }
}

.event-sessions-list-view {
    .bs-agenda-item-capacity-stats-display-cell {
        min-width: 55px;
    }
}
.event-asset-manager-list {
    list-style-type: none;
    margin-left: 0;
    li {
        float: left;
        min-width: 16%;
        margin-right: 8px;
        margin-bottom: 18px;
        @media (max-width: 480px) {
            min-width: 100%;
        }
    }
    .thumbnail {
        position: relative;
        cursor: pointer;
        background: transparent;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        border: 1px solid #dfe4eb;
        width: 80px;
        height: 80px;
        margin: 0 auto 16px auto;
        &:after {
            content: '↻ Replace';
            display: block;
            position: absolute;
            right: -2em;
            bottom: 0;
            font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial;
            background: rgba(255, 255, 255, 1);
            font-size: 1em;
            padding: 1em;
            opacity: 0;
            transition: all 0.2s ease;
            padding-right: 1.3em;
            white-space: nowrap;
        }
        &:hover {
            &:after {
                right: 0;
                opacity: 1;
            }
        }
    }
    .caption {
        font-size: 0.85em;
        text-align: center;
    }
    .current-asset-stage {
        text-align: center;
        img {
            max-height: 128px;
        }
    }
    .pretty-list {
        margin-right: 18%;
        height: 100%;
        float: left;
    }
}

.pax-photo-replacement-trigger-container,
.event-asset-manager-row,
.data-import-upload-view {
    input[type='file'] {
        padding: 1px;
    }
    .batch-file-download-group-header {
        border: 1px solid color(base-100);
        margin-bottom: 30px;
        padding: 0 18px 19px;
        h6 {
            color: color(base-700);
            background: color(base-100);
            border-bottom: 1px solid color(base-100);
            margin: 0 -18px 16px -18px;
            padding: 2px 12px;

            .icon-download {
                margin-right: 5px;
            }
        }
    }
}

.event-asset-manager-row .event-asset-manager-list {
    .thumbnail:after {
        padding: 0;
        font-size: 10px;
        border: 5px solid color(base-000);
    }
}

.app-activation-modal-view {
    .modal-body .well h3 {
        text-align: center;
        font-weight: 200;
    }
}
