@page {
    size: A4;
}

@media print {
    html, body {
        width: 210mm;
        height: 297mm;
    }

    .content-wrapper {
        max-width: none;
        min-width: 0;
        padding: 0 !important;

        > .ng-view > ng-include {
            display: none !important;
        }
    }

    .modal-diagnostics,
    nav.main,
    .current-module-nav-wrapper,
    .fullscreen-controls-container,
    .smart-table-global-search,
    .column-actions {
        display: none !important;
    }

    #privileged-content-wrapper {
        margin-left: 0;
        min-height: 0;
    }

    .span9, .span3 {
        float: none;
        width: auto;
    }
}
