$entitlement-plan-card-width: 202px;

.entitlement-plans {
    margin-bottom: box-model(xl);

    .listing-grid {
        grid-template-columns: repeat(auto-fill, minmax(#{$entitlement-plan-card-width}, 1fr));
    }

    .card .badge {
        position: static;
    }
}

// Additional styles for `no-grid` browsers
.no-grid-supported .entitlement-plans .listing-grid .card {
    width: $entitlement-plan-card-width - (box-model(xl)) - 2px; // 2px = side borders
}
