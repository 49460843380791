.marketplace {
    @include line-height(base);

    .search-bar {
        margin-bottom: box-model(m);
    }

    .dropdown {
        margin-left: box-model(xs);
    }

    .package-button {
        margin-left: box-model(xs);
        i {
            display: none;
        }
    }

    .list-tile {
        text-align: center;
        padding: box-model(m);

        .package-button, .package-button button {
            margin: 0;
        }
    }

    .installed-modules {
        .update-all {
            float: right;
        }

        td {
            &.version {
                text-align: right;
            }

            .badge {
                margin-left: box-model(xs);
            }
        }
    }
}
