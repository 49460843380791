.step-pane {
    display: none;

    &.active {
        display: block;
    }
}

.field-box {
    @extend %form-group;
}

.step-content, .wizard-actions {
    @extend .span10;
    margin: 0 auto;

    .span10 {
        width: 100%;
    }
}

.wizard-actions {
    text-align: right;
    padding: box-model(l, 0);
}

.wizard {
    .modal-header h1 {
        text-align: center;
    }
}

.create-workspace {
    .template-choice {

        .multiselect.form-control {
            max-width: box-model(xs) * 40;
        }

        .form-group.organization {
            flex: 1 1 36%;
            padding-right: 0;
        }

        .templates-list {
            margin-bottom: box-model(xl);

            &:last-child {
                margin-bottom: 0;
            }

            &.fake {
                height: box-model(xs) * 52; // 416px
                overflow: hidden;
                margin-bottom: - box-model(l);

                // patches the hidden overflow on the focused selector.
                padding: 1px;
                margin-left: -1px;
                margin-right: -1px;
            }

            .details {
                display: block;
            }

            .visibility {
                display: none;
            }
        }
    }

    .form-group + .form-group {
        .mx-datepicker-popup {
            transform: translateX(- box-model(xxxl));
        }
    }
}

.pkg-upgrade {
    .updatables-list {
        margin-bottom: box-model(l);

        &:last-child {
            margin-bottom: 0;
        }

        header .badge {
            margin-right: box-model(xs);
        }
    }

    .progress {
        margin-top: 0;
    }
}

.connect-events-wizard .search {
    p {
        display: none;
    }
}

.create-content-hub, .connect-events-wizard {
    .content-hub-form {
        .expandable {
            border: 1px solid color(base-300);
            border-radius: $large-border-radius;
            padding: box-model(s);
            margin-bottom: box-model(xl);

            .toggler > a {
                font-size: 10px;
                text-align: right;
                text-transform: uppercase;
            }

            > header {
                transition: margin-bottom .2s ease-in-out;
            }

            > section {
                padding-top: 0;
                transition: max-height .2s ease-in-out;
            }

            &.open {
                > header {
                    margin-bottom: box-model(s);
                }

                > section {
                    max-height: box-model(xxl);
                }
            }

        }
    }

    .workspaces-list {
        margin-bottom: box-model(l);
    }

    .search {
        padding: box-model(xs, 0);
        margin-bottom: box-model(l);

        .btn-group {
            margin-left: box-model(m);
        }

        .search-bar {
            margin-bottom: box-model(m);
        }

        p {
            margin: 0;
        }

        small {
            display: block;
        }
    }

    section.general {
        margin-bottom: box-model(m);
    }

    section.events {
        display: flex;

        .label {
            flex: 1 1 17%;
            padding: box-model(xs, s);
        }

        .info {
            flex: 1 1 40%;
        }
    }

    .vue-portal-target {
        text-align: left;
        float: left;
    }

    .connect-event {
        /**
         * This is an hack to prevent CLS (https://web.dev/cls/) while
         * selecting/filtering  workspaces or webinars within the wizard
         * modal which is centered in the window and adapts its height
         * based on the displayed content.
         *
         * The "hack" consists in letting these componets to occupy
         * the full available height, so that user's action will not
         * make the UI jump around.
         */
        min-height: 71.8vh;
    }

    .content-hub-summary {
        td.status, td.actions {
            width: box-model(xs) * 10;
        }
    }
}

.create-webinar-from-template {
    .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
