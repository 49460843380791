.excel-import-wizard-container {
    .actions {
        padding: box-model(m, 0);
    }

    // Tables

    tr.created {
        background-color: color(success-100);
    }

    tr.updating-fields, tr.type {
        background-color: color(base-100);
    }

    tr.unchanged {
        background-color: color(warning-100);
    }

    tr.total {
        background-color: color(primary-100);
    }

    tr.removed {
        background-color: color(error-100);
    }

    tr.no-field-match, tr.updated {
        background-color: color(base-300);
    }

    .smart-table-inner-wrapper {
        td {
            white-space: nowrap;
        }
    }
}
