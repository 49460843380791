.cloud-storage-file-uploader {
    .toolbar {
        text-align: right;
        margin-bottom: box-model(xs);
    }

    .preview {
        picture {
            display: block;
            text-align: center;
            cursor: zoom-in;

            img {
                max-height: box-model(m) * 10;
            }
        }
    }

    .info {
        margin-top: box-model(m);
    }
}

.modal-wrapper.uploader {
    .hint, .email-notification{
        margin: box-model(0, xxl, 0, xxl);
        p {
            margin: 0;
        }
    }

    .email-notification{
        padding-bottom: box-model(m);
    }

    .subtitle {
        @include line-height(s);

        color: color(base-700);
        margin-bottom: box-model(s);
    }
}
