.registration-type {
    display: flex;
    gap: box-model(m);

    label {
        border-radius: $large-border-radius;
        border: 1px solid color(base-300);
        box-shadow: box-model(0, xxxs, xxxs) color(base-100);
        flex: 1 1 percentage(math.div(1, 3));
        padding: box-model(m);
        position: relative;
        text-align: center;

        &.radio {
            margin-bottom: 0;
        }

        &.selected {
            background-color: color(primary-100);
            color: color(primary-300);
            border-color: color(primary-300);

            .desc {
                color: color(base-700);
            }
        }

        > span {
            display: block;

            &.text {
                @include font-weight(semibold);
                @include line-height(base);
            }

            &.desc {
                @include font-size(xs);
                @include line-height(document);
                color: color(base-600);
            }
        }
    }

    input {
        height: 1px;
        width: 1px;
        position: absolute;
        opacity: 0;
    }
}
