.form-groups {
    display: block;
}

.grouped-controls .form-groups > .form-group {
    margin-bottom: box-model(m);
}

.mx-datepicker {
    width: 100% !important;
}
