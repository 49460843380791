// BREADCRUMBS
// -----------

.breadcrumb {
    padding: 7px 14px;
    margin: 0 0 $baseLineHeight;
    background-color: linear-gradient(to top, #fff, #f5f5f5);
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: inset 0 1px 0 #fff;
    li {
        display: inline-block;
        text-shadow: 0 1px 0 #fff;
    }
    .divider {
        padding: 0 5px;
        color: $grayLight;
    }
    .active a {
        color: $grayDark;
    }
}
