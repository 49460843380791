.supported-browsers {
    $margin-between-browsers: box-model(xl);
    $browsers-per-row: 4;
    $browser-width: ($blocking-message-container-width - math.div(($browsers-per-row - 1) * $margin-between-browsers, $browsers-per-row));

    width: $blocking-message-container-width;
    margin-top: 15%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .body {
        @include font-size(document);
        margin-left: box-model(l);
        margin-right: box-model(l);
    }

    .browser-list {
        margin-top: box-model(xl);
        display: flex;
        gap: $margin-between-browsers;
    }

    .browser-info {
        border-radius: $base-border-radius;
        background-color: color(base-100);
        width: $browser-width;
        padding-top: box-model(l);
        padding-bottom: box-model(s);
        color: color(base-700);

        .name {
            display: block;
            font-weight: font-weight(bold);
        }

        .company {
            display: block;
            color: color(base-600);
        }

        img {
            height: icon-size(l);
            width: icon-size(l);
        }
    }

    .link {
        margin-top: box-model(l);
    }
}
