.standalone-studio {
    .stream-controls {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid color(base-300);

        .back-button-text {
            font-size: font-size(s);
            margin-left: 2px;
        }

        .meta {
            display: flex;
            align-items: center;
            max-width: 25%;

            .buttons, .info {
                display: inline-block;
                vertical-align: middle;
            }

            .buttons {
                padding: box-model(xs);

                + .info {
                    padding-left: 0;
                }

                button {
                    position: relative;

                    a {
                        position: absolute;
                        left: 0;
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .info {
            display: flex;
            flex-wrap: wrap;
            white-space: nowrap;
            align-items: center;
            max-width: 25%;

            @include line-height(xxs);
            padding: box-model(xxs, xs);

            .title {
                @include font-weight(semibold);
                margin: box-model(0, xs, 0, 0);
            }

            .title, .event-name {
                @include ellipsis;

                max-width: 100%;
            }

            .event-name {
                @include font-size(s);

                color: color(base-600);
                line-height: 1;
            }
        }

        .stats {
            display: flex;
            flex-grow: 1;

            .stat-card {
                flex: 1 1 percentage(math.div(1, 3));
                border-right: 1px solid color(base-300);

                &.columns {
                    display: flex;
                }

                &:last-child {
                    border-right: 0;
                }
            }
        }

        .controls {
            width: 280px;

            .countdown {
                width: box-model(h);
            }
        }

        .btn.single {
            padding: 0 box-model(xxs);
            width: unset;
            height: box-model(l);

            i {
                @include line-height(xs);
            }
        }

        .ghost-indicator {
            display: flex;
            align-items: center;
            margin: box-model(xs);
            height: box-model(l);
            border-radius: $base-border-radius;
            background-color: color(primary-050);
            color: color(primary-300);

            .ghost-indicator-text {
                padding: box-model(0, xs);
            }

            i {
                padding: box-model(0, 0, 0, xs);
            }
        }

    }
}
