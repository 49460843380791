.org-data-edit-view {
    .button-navigation {
        margin-top: box-model(xl);
        text-align: right;
        border-top: 1px solid color(base-500);
        button {
            margin-top: box-model(m);
        }
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
}
