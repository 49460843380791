.members-list {
    table {
        .name {
            @include font-weight(semibold);
        }
    }
}

.remove-member-modal {
    .badge {
        margin-left: 0;
    }
}
