.content-wrapper.document-editor:not(:has(.package-inspector)) {
    padding: 0;
    max-width: none;
}

.page.document-editor {
    padding: 0;
    max-width: none;
}

.document-editor:not(:has(.package-inspector)) {
    height: 100vh;
    padding-left: box-model(l);
}

.document-editor {
    display: flex;
    flex-direction: column;

    .dev-tool {
        padding: box-model(xxl);
    }

    code {
        background-color: transparent;
        border: 0;
        color: inherit;
        padding: 0;
    }

    .heading {
        display: flex;
        flex: 1 0 auto;
        align-items: center;
        min-height: 0;

        ul.tabs {
            flex: 1 1 auto;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            height: 100%;

            li {
                color: color(base-500);
                border-bottom: 2px solid transparent;

                &.active {
                    color:  color(primary-300);
                    border-color: color(primary-300);
                }
            }

            .new-doc {
                a {
                    padding: box-model(xxxs, xs);
                    display: block;
                }

                i {
                    @include icon-size(xs);
                    vertical-align: middle;
                }
            }
        }

        .search-bar {
            flex: 1 1 auto;
            padding: box-model(xs, s);

            .form-group {
                width: box-model(xs) * 40;
            }
        }
    }

    .menu:has(.suggestion) {
        max-height: box-model(xs) * 44;
    }

    .menu li a.suggestion {
        @include font-size(s);
        @include line-height(xxs);

        span.id, span.ext {
            display: block;
        }

        span.ext {
            color: color(base-500);
        }

        span.type, span.title {
            @include font-weight(semibold);

            color: color(base-500);
        }
    }

    .panes {
        flex: 1 1 100%;
        display: flex;
        min-height: 0;
        position: relative;
        overflow: auto;
    }

    .diff-viewer {
        width: 100%;
    }
}

.backstage-icons {
    padding: box-model(l);

    .search-bar {
        float: right;
    }

    .icons-list {
        @include grid(12);
        overflow: auto;

        .box {
            @extend %card-with-borders;
            @include cols(2);
            text-align: center;
            box-shadow: 0 2px 2px rgb(45 51 57 / 4%);
            padding: box-model(m);

            h3 {
                @include font-weight(semibold);
                @include font-size(document);
                border: 0;
                margin: 0;
            }

            ul {
                margin: 0;
            }
        }

        .type {
            display: inline-block;
            background-color: color(base-100);
            border-radius: box-model(xxs);
            margin: 0 auto box-model(s);
            padding: box-model(xxs);
        }

        i {
            @include font-size(xl);
        }

        code {
            @include font-size(xs);
        }
    }
}

.document-tab {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: box-model(xxs, 0);
    max-width: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: max-width .2s ease-in-out, padding .2s ease-in-out;

    &.ready {
        max-width: box-model(xs) * 30;
        padding: box-model(xxs);
    }


    i + .name {
        margin-left: box-model(xxs);
    }

    .name {
        @include ellipsis;
        min-width: 0;

        + i {
            margin-left: box-model(xs);
        }
    }

    i {
        @include icon-size(xxs);
        vertical-align: middle;
    }
}

.document-panel {
    display: flex;
    flex: 1;

    .meta {
        width: box-model(xs) * 40;
        min-width: box-model(xs) * 40;
        flex: 1 1 auto;
        display: flex;
        padding: box-model(0, s);
        flex-direction: column;
        overflow: auto;

        > * {
            max-width: 100%;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .alert {
            @include line-height(xxs);
            @include ellipsis;

            margin-bottom: 0;
        }
    }

    .document-info,
    .revisions,
    .linked-documents,
    .document-attachments {
        flex: 0 1 auto;
        margin-bottom: box-model(s);
    }

    .revision, .linked-doc, .attachment {
        display: flex;
        column-gap: box-model(xs);
        align-items: center;
        padding: box-model(xs, s);
        margin: box-model(0, -s);

        &:hover {
            background-color: color(base-100);
        }

        &.selected {
            background-color: color(primary-100);
            cursor: default;
        }

        .details {
            min-width: 0;
        }

        .number {
            @include font-size(l);
            padding: box-model(xs, xs, xs, 0);
        }

        .id {
            @include line-height(xxs);
            @include ellipsis;
        }

        .status {
            @include font-size(s);
            @include line-height(xxs);
            color: color(base-500);
        }
    }

    .linked-doc, .attachment {
        cursor: pointer;

        .type, .media {
            i {
                @include icon-size(s);
            }
        }

        .details {
            @include font-size(s);
            @include line-height(xxs);
        }

        .name {
            @include font-weight(semibold);

            .t {
                color: color(base-500);
            }
        }

        .ext {
            color: color(base-500);
        }

        .media {
            width: box-model(xl);
            margin-right: box-model(xs);

            img {
                vertical-align: middle;
            }
        }
    }
}

.document-info {
    @include font-size(s);
    @include line-height(xs);

    background-color: color(base-000);
    padding: box-model(xs, s);
    margin: box-model(0, -s);
    position: sticky;
    top: 0;

    .owner {
        float: right;

        .badge {
            margin: 0;
        }

        i {
            display: inline-block;
            margin-top: box-model(-xxxs);
            margin-left: 0;
        }
    }

    span, code:not(.line) {
        &::after {
            content: "\a";
            white-space: pre;
        }
    }

    i {
        margin: box-model(0, xxs);
        vertical-align: middle;
    }

    .btn-mini {
        &:first-child {
            margin-left: 0;
        }
    }

    .alert.timestamp {
        margin-bottom: box-model(s)
    }

    .actions {
        margin-top: box-model(xs);
    }
}

.d2h-diff-table {
    @include font-size(s);
    @include line-height(xs);

    td {
        padding: box-model(0, xxs);
    }
}

.d2h-file-wrapper {
    border: 0;
    margin-bottom: 0;
}

.d2h-file-header {
    background-color: color(base-000);
}

.d2h-file-name-wrapper {
    @include font-size(s);
    @include line-height(xs);
}
