// ===========================
// Defaults and common devices
// ===========================

@import "default/base";
@import "default/dark-mode";

// ===========
// Extra Large
// ===========

// Extra large up
@media (min-width: $screen-xl-min) {
    @import "extra-large/base";
}

// =====
// Large
// =====

// Large up
@media (min-width: $screen-lg-min) {
    @import "large-up/base";
}

// Large only
@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    @import "large/base";
}

// Large down
@media (max-width: $screen-lg-max) {
    @import "large-down/base";
}

// ===========
// Middle size
// ===========

// Mid up
@media (min-width: $screen-md-min) {
    @import "mid-up/base";
}

// Mid only
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @import "mid/base";
}

// Mid down
@media (max-width: $screen-md-max) {
    @import "mid-down/base";
}

// ==========
// Small size
// ==========

// Small up
@media (min-width: $screen-sm-min) {
    @import "small-up/base";
}

// Small only
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @import "small/base";
}

// Small down
@media (max-width: $screen-sm-max) {
    @import "small-down/base";
}

// ===========
// Extra small
// ===========

// Extra small only
@media (max-width: $screen-xs-max) {
    @import "extra-small/base";
}

// ========
// Printers
// ========

// All printers
@import "print/base";
