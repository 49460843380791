body.maximized .fullscreen-controls-container {
    display: block;
}

.fullscreen-controls-container {
    display: none;
    position: fixed;
    z-index: z-index(fullscreen-controls);
    transform: translateX(70%);
    transition: $base-transform-transition;
    right: 0;

    &:hover {
        transform: translateX(0);

        .control {
            opacity: 1;
        }
    }

    .control {
        background-color: colorAlpha(base-700, .2);
        transition: $base-opacity-transition;
        opacity: .5;

        &.gofullscreen {
            border-top-left-radius: $base-border-radius;
        }

        &.maximize {
            border-bottom-left-radius: $base-border-radius;
        }

        i {
            @include icon-size(m, 50);
            cursor: pointer;
        }
    }
}
