/**
 * NOTE: .dropdown-menu and .open comes from bootstrap legacy.
 * It would be nice to remove them at some point.
 */

%dropdown-menu {
    @include font-size(base);
    @include line-height(base);

    background-color: color(base-000);
    border: 1px solid color(base-500);
    border-radius: $base-border-radius;
    box-shadow: $base-box-shadow;
    display: inline-block;
    margin-top: box-model(xs);
    max-height: $dropdown-menu-max-height;
    max-width: $dropdowns-max-width;
    min-width: $dropdowns-min-width;
    opacity: 0;
    overflow: auto;
    position: absolute;
    text-align: left;
    transition: $base-opacity-transition, $base-z-index-transition;
    z-index: -1;

    &.dropdown-vue {
        visibility: hidden;

        &.visible {
            visibility: visible;
        }

        &.anchor-nw {
            transform: translate3d($button-max-height * -1, 0, 0);
        }

        &.anchor-ne {
            transform: translate3d(-100%, 0, 0);
        }

        &.anchor-se {
            margin-top: 0;
            transform: translate3d(-100%, calc(-100% - #{$button-max-height + box-model(xs)}), 0);
        }

        &.anchor-sw {
            margin-top: 0;
            transform: translate3d(0, calc(-100% - #{$button-max-height + box-model(xs)}), 0);
        }
    }

    &:not(.dropdown-vue) {
        &.anchor-nw {
            margin-left: $button-max-height * -1;
        }

        &.anchor-ne {
            transform: translateX(-100%);
        }

        &.anchor-se {
            margin-top: 0;
            transform: translate3d(-100%, calc(-100% + #{($button-max-height +  box-model(-xs))}) , 0);
        }

        &.anchor-sw {
            margin-top: 0;
            transform: translate3d($button-max-height * -1, calc(-100% + #{($button-max-height +  box-model(-xs))}) , 0);
        }
    }

    li {
        margin: 0;

        &:hover, &.selected {
            background-color: color(primary-100);

            &:first-child {
                border-radius: $base-border-radius $base-border-radius 0 0;
            }

            &:last-child {
                border-radius: 0 0 $base-border-radius $base-border-radius;
            }
        }

        a, .a {
            @include line-height(base);
            @include ellipsis;

            cursor: pointer;
            display: block;
            padding: box-model(xs, $dropdowns-side-paddings);
            color: color(base-700);

            &:hover {
                // This is needed for dropdowns nested in sidemenus
                background-color: transparent;
            }

            &.danger {
                color: color(error-300);
            }

            &.success {
                color: color(success-300);
            }

            i {
                @include font-size(document);
                margin-right: $icon-side-margin;
                vertical-align: middle;
            }
        }
    }
}

%opaque-forecomponent {
    opacity: 1;
    z-index: z-index(dropdowns);
}

.dropdown {
    display: inline-block;

    &.in, &.open {
        .activator {
            .btn {
                background-color: color(base-500);
                color: color(base-700);
            }
        }

        .menu, .dropdown-menu {
            @extend %opaque-forecomponent;
        }
    }

    &.embedded {
        .activator .btn {
            background-color: transparent;
        }
    }

    &.safe-position {
        .menu-wrapper {
            position: static;
        }
    }

    .menu-wrapper {
        height: 0;
    }

    .menu {
        @extend %dropdown-menu;
    }

    .activator {
        button.default {
            // Following measures are specific for the default icon.
            padding: 0 7px;

            i {
                font-size: 18px;
                line-height: 1.8;
            }
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

ul.dropdown-menu {
    @extend %dropdown-menu;

    list-style-type: none;
    margin: $button-max-height + box-model(xs) 0 0;
    padding: 0;
}

.sidemenu .dropdown ul li {
    &.separator {
        padding: 0;
        margin: 0;
    }
}

// Bootstrap legacy
.dropdown-toggle {
    max-height: $button-max-height;

    .caret {
        @include iconify(ic-chevron-down);
    }
}
