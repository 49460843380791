@keyframes progressBarFadeIn {
    0% {
        opacity: 0;
    }
    82% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes progressBarWarp {
    0% {
        transform: translate(-100%) scaleX(0);
    }
    50% {
        transform: translate(0%) scaleX(0.5);
    }
    100% {
        transform: translate(300%) scaleX(0);
    }
}

@keyframes progressBarRainbow {
    0% {
        background: $spotme-grey;
    }
    19% {
        background: $spotme-grey;
    }

    20% {
        background: $spotme-orange;
    }
    39% {
        background: $spotme-orange;
    }

    40% {
        background: $spotme-yellow;
    }
    59% {
        background: $spotme-yellow;
    }

    60% {
        background: $spotme-green;
    }
    79% {
        background: $spotme-green;
    }

    80% {
        background: $spotme-cyan;
    }
    100% {
        background: $spotme-cyan;
    }
}

.new-progress-bar {
    $bar-height: 6px;
    height: $bar-height;
    border-radius: $bar-height;
    overflow: hidden;
    position: relative;

    transition: opacity 0.15s linear;
    animation: progressBarFadeIn 1s linear;

    .bar {
        width: 40%;
        height: $bar-height;
        background: transparent;
        transform-origin: center center;
        animation: progressBarWarp 1s linear infinite,
            progressBarRainbow 5s linear infinite;
        animation-delay: 0.01s;
        position: absolute;
        top: 0;
        left: 0;
    }
}
