.double-stat {
    color: color(base-700);
    padding: box-model(m);
    border-top-width: box-model(xxs);
    display: flex;
    flex-direction: column;

    &.card .badge {
        position: static;
        margin: box-model(0, xs, 0, 0);
    }

    .name {
        @include font-size(base);
        @include line-height(s);
        @include font-weight(normal);
        margin-bottom: box-model(xs);
    }

    .sub {
        margin-bottom: box-model(xs);
    }

    .stats {
        display: flex;
        margin-bottom: box-model(s);

        .count {
            @include font-size(xxm);
            @include line-height(xxm);
            @include font-weight(semibold);
        }

        .label {
            @include font-size(s);
            @include line-height(document);
        }
    }

    .stat-a, .stat-b {
        flex: 0 0 50%;
    }

    .stat-a:has(+ .stat-b) {
        border-right: 1px solid color(base-300);
    }

    .stat-b {
        padding-left: box-model(l);
    }

    .sub, .stat-c .label {
        @include font-size(s);
        @include line-height(document);

        color: color(base-500);
    }

    .footer {
        display: flex;
        flex: 1 1 auto;
    }

    .footer-content {
        display: inline-block;
        align-self: flex-end;
    }
}
