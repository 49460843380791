.pagination {
    ul {
        padding: 0;
    }
}

.paginator {
    margin: box-model(m, 0);
    display: flex;
    justify-content: flex-end;

    .page-number {
        flex: 0 0 auto;
        &:not(.primary) {
            background-color: color(base-100);
            &:hover {
                background-color: color(base-305);
            }
        }

        [class^="ic-"] {
            vertical-align: text-bottom;
        }
    }

    .number-separator {
        cursor: default;
        pointer-events: none;
    }
}
