.creative-editor {

    $topPadding: box-model(m) + box-model(l); // As declared in layout.scss
    $tabBarHeight: box-model(xxl) + box-model(l); // Height + bottom margin
    $bottomPadding: box-model(xl) + $indirect-button-heights;  // As declared in layout.scss

    height: calc(100vh - #{$topPadding + $tabBarHeight + $bottomPadding });

    .badge-layout {
        height: 100%;
        flex-wrap: nowrap;

        .panel.panel { // Not an error
            display: flex;
            flex-direction: column;
            max-height: 100%;
            flex-grow: 1;
            flex-shrink: 0;

            > * {
                flex: 0 0 auto;
                min-height: 0;

                &.design-editor,
                &.design-preview {
                    flex: 1 1 75%;
                    overflow: auto;
                }

                &.json-editor {
                    flex-shrink: 1;
                }
            }
        }
    }

    canvas {
        cursor: default !important;
    }

}

.design-editor,
.design-preview {
    box-sizing: border-box;

    .design {
        border: 1px solid color(base-300);
        font-size: 0;
        line-height: 1;
        text-align: center;
    }

    .canvas-container {
        margin: 0 auto;
    }

    canvas {
        max-width: 100%;
    }
}
