.scheduled-emails {
    .search-bar {
        float: right;
    }

    td.template {
        @include line-height(xxm);
        @include ellipsis;
        max-width: box-model(xs) * 42;
    }

    td.status {
        &.cancelled, &.failed {
            i {
                color: color(error-300);
            }
        }

        &.sent {
            i {
                color: color(success-300);
            }
        }

        i {
            @include font-size(document);

            vertical-align: middle;
            margin-right: box-model(s);
        }

        .scheduled-at {
            margin-left: box-model(s);
        }
    }

    td.recipients {
        i {
            vertical-align: middle;
            margin-left: box-model(xxs);
            line-height: inherit;
            display: inline-block;
            margin-top: box-model(xxxs) * -1;
        }
    }
}
