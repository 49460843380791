.json-editor.json-editor { // The double selector is not an error.
    /* over all fonts, sizes, and colors */
    --jse-font-size-mono: #{font-size(s)};
    --jse-theme-color: #{color(base-100)};
    --jse-theme-color-highlight: #{color(base-100)};
    --jse-background-color: #{color(base-000)};
    --jse-text-color: #{color(base-700)};
    --jse-text-color-inverse: #{color(base-100)};

    /* main, menu, modal */
    --jse-main-border: 1px solid #{color(base-300)};
    --jse-menu-color: #{color(base-700)};
    --jse-modal-background: #{color(base-000)};
    --jse-modal-overlay-background: #{colorAlpha(base-000, 0.5)};
    --jse-modal-code-background: #{color(base-000)};

    /* tooltip in text mode */
    --jse-tooltip-color: var(--jse-text-color);
    --jse-tooltip-background: #{color(base-000)};
    --jse-tooltip-border: 1px solid #{color(base-500)};
    --jse-tooltip-action-button-color: inherit;
    --jse-tooltip-action-button-background: #{color(base-500)};

    /* panels: navigation bar, gutter, search box */
    --jse-panel-background: #{color(base-100)};
    --jse-panel-background-border: 1px solid #{color(base-305)};
    --jse-panel-color: var(--jse-text-color);
    --jse-panel-color-readonly: #{color(base-500)};
    --jse-panel-border: 1px solid #{color(base-300)};
    --jse-panel-button-color-highlight: #{color(base-600)};
    --jse-panel-button-background-highlight: #{color(base-305)};

    /* navigation-bar */
    --jse-navigation-bar-background: #{color(base-500)};
    --jse-navigation-bar-background-highlight: #{color(base-550)};
    --jse-navigation-bar-dropdown-color: var(--jse-text-color);

    /* context menu */
    --jse-context-menu-background: #{color(base-000)};
    --jse-context-menu-background-highlight: #{color(base-500)};
    --jse-context-menu-separator-color: #{color(base-500)};
    --jse-context-menu-color: var(--jse-text-color);
    --jse-context-menu-pointer-background: #{color(base-500)};
    --jse-context-menu-pointer-background-highlight: #{color(base-600)};
    --jse-context-menu-pointer-color: var(--jse-context-menu-color);

    /* contents: json key and values */
    --jse-value-color: var(--jse-text-color);
    --jse-edit-outline: 2px solid var(--jse-text-color);

    /* contents: selected or hovered */
    --jse-selection-background-color: #{color(base-305)};
    --jse-selection-background-inactive-color: #{color(base-100)};
    --jse-hover-background-color: #{color(base-100)};
    --jse-active-line-background-color: #{colorAlpha(base-700, 0.06)};
    --jse-search-match-background-color: #{color(base-100)};

    /* contents: section of collapsed items in an array */
    --jse-collapsed-items-background-color: #{color(base-100)};
    --jse-collapsed-items-selected-background-color: #{color(base-550)};
    --jse-collapsed-items-link-color: #{color(base-605)};

    /* contents: inline tags inside the JSON document */
    --jse-tag-background: #{color(base-100)};
    --jse-tag-color: #{color(base-600)};

    /* contents: table */
    --jse-table-header-background: #{color(base-100)};
    --jse-table-header-background-highlight: #{color(base-300)};
    --jse-table-row-odd-background: #{colorAlpha(base-700, 0.1)};

    /* controls in modals: inputs, buttons, and `a` */
    --jse-input-background: #{color(base-100)};
    --jse-input-border: var(--jse-main-border);
    --jse-button-background: #{color(base-600)};
    --jse-button-background-highlight: #{color(base-550)};
    --jse-button-color: #{color(base-000)};
    --jse-button-secondary-background: #{color(base-300)};
    --jse-button-secondary-background-highlight: #{color(base-550)};
    --jse-button-secondary-background-disabled: #{color(base-305)};
    --jse-button-secondary-color: var(--jse-text-color);
    --jse-a-color: #{color(primary-300)};
    --jse-a-color-highlight: #{color(primary-305)};

    /* svelte-select */
    --jse-svelte-select-background: #{color(base-100)};
    --jse-svelte-select-border: 1px solid #{color(base-100)};
    --list-background: #{color(base-100)};
    --item-hover-bg: #{color(base-500)};
    --multi-item-bg: #{color(base-550)};
    --input-color: #{color(base-700)};
    --multi-clear-bg: #{color(base-600)};
    --multi-item-clear-icon-color: #{color(base-700)};
    --multi-item-outline: 1px solid #{color(base-500)};
    --list-shadow: 0 2px 8px 0 #{colorAlpha(base-000, 0.4)};

    /* color picker */
    --jse-color-picker-background: #{color(base-500)};
    --jse-color-picker-border-box-shadow: #{color(base-600)} 0 0 0 1px;

    display: flex;
    flex: 1 1 auto;
    width: 100%;
    position: relative;
    min-height: 0;

    > .actions {
        position: absolute;
        top: box-model(xxs) + 1; // +1 because of the editor border
        left: box-model(xs);
        z-index: 1;
    }

    > .second-toolbar {
        position: absolute;
        top: box-model(xxs) + 1; // +1 because of the editor border
        right: box-model(xs);
        z-index: 1;
    }

    .jse-menu.jse-menu {
        border-top-left-radius: $base-border-radius;
        border-top-right-radius: $base-border-radius;
        background-color: color(base-100);
        padding-left: (box-model(xs) * 10) + box-model(xxs); // 10 for the buttons .5 for spacing from the actions

        .jse-button:hover, .jse-button:focus {
            color: color(primary-300);
        }

        .jse-button {
            align-self: center;

            svg {
                vertical-align: middle;
            }
        }

        .jse-button.jse-group-button {
            @include primary-font();
            @include font-size(base);
            @include line-height(xxs);

            padding: math.div(box-model(s), 2) box-model(s);
            border-color: color(base-300);
            height: auto;
            text-transform: capitalize;

            &.jse-first {
                border-top-left-radius: $base-border-radius;
                border-bottom-left-radius: $base-border-radius;
            }

            &.jse-last {
                border-top-right-radius: $base-border-radius;
                border-bottom-right-radius: $base-border-radius;
            }

            &.jse-selected {
                background-color: color(primary-300);
                border-color: color(primary-300);
            }
        }
    }

    .jse-text-mode.jse-text-mode .jse-contents {
        border-left: 0;
    }

    .jse-contents .cm-editor {
        --indent-marker-bg-color: #{color(base-300)};
        --indent-marker-active-bg-color: #{color(base-305)};
    }

    .jse-jsoneditor-modal.jse-modal.jse-modal .jse-modal-contents {
        .jse-actions button.jse-primary {
            @include font-size(base);
            @include line-height(xxs);

            padding: math.div(box-model(s), 2) box-model(s);
            border-radius: $base-border-radius;
        }

        .jse-modal-inline-editor {
            border: 1px solid color(base-500);
            border-radius: $base-border-radius;
            overflow: hidden;
        }
    }

    .jse-table-mode-welcome.jse-table-mode-welcome .jse-nested-arrays button.jse-nested-array-action {
        @include font-size(base);
        @include line-height(xxs);

        padding: math.div(box-model(s), 2) box-model(s);
        border-radius: $base-border-radius;
    }

    .editor {
        width: 100%;
    }
}

.theme-dark .json-editor.json-editor { // The double selector is not an error.
    --jse-theme: dark;
    --jse-collapsed-items-link-color-highlight: #ec8477;

    /* contents: highlighting of search results */
    --jse-search-match-color: #724c27;
    --jse-search-match-outline: 1px solid #966535;
    --jse-search-match-active-color: #9f6c39;
    --jse-search-match-active-outline: 1px solid #bb7f43;

    /* contents: json key and values */
    --jse-key-color: #9cdcfe;
    --jse-value-color-number: #b5cea8;
    --jse-value-color-boolean: #569cd6;
    --jse-value-color-null: #569cd6;
    --jse-value-color-string: #ce9178;
    --jse-value-color-url: #ce9178;
    --jse-delimiter-color: #949494;
}
