.column-org-members-list {

    .name {
        @include font-weight(semibold);
    }

    .email {
        @include font-size(s);
        @include line-height(xs);
        color: color(base-500);
    }

    .pretty-list.users-list {
        .search-bar {
            margin-top: box-model(xs) * -7;
        }

        .table-wrapper {
            max-height: calc(100vh - #{box-model(m) * 15});
        }

        [class*='icon-'] {
            font-size: initial;
        }
    }

    .toggler {
        &.disabled {
            .switch-off {
                opacity: 0.5;
            }
        }
    }

    .select2-container {
        margin-bottom: 0;
    }
}
