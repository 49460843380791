.event-settings.subpage {
    padding-bottom: box-model(m);

    fieldset {
        margin-bottom: box-model(xxl);
    }

    .support-options fieldset,
    fieldset.templating {
        button {
            float: right;
        }
    }

    .templating, .users, .general-data {
        .alert {
            margin-bottom: box-model(m);
        }
    }

    .support-options {
        fieldset {
            margin-bottom: 0;
        }

        h5 {
            margin-bottom: box-model(m);
        }
    }

    .app_access .webapp_access {
        display: block;
        margin-bottom: box-model(m);
    }
}
