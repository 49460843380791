// --- textAngular stuff

.ta-editor {
    min-height: 600px;
    height: auto;
    overflow: auto;
    font-family: inherit;
    font-size: 100%;
    margin: 20px 0;
}
.ta-scroll-window.form-control {
    border: 1px solid #ccc;
    .ta-bind {
        height: 100%;
        &:focus {
            outline: none;
        }
    }
}
