.platform-pdf-documents {

    .platform-pdfs {
        margin: box-model(l) 0;

        h4 {
            font-size: 1em;
        }
    }

    .pdf-url-by-language {
        display: inline;

        .row {
            display: grid;
            grid-template-columns: 1fr auto;
            column-gap: box-model(s);
            margin-left: 0;

            .form-group {
                display: inline;
                margin-bottom: box-model(s);
            }
        }
    }
}
