.webinars-list {
    .ic-report {
        color: color(base-700);

        &:hover {
            color: color(primary-300);
        }
    }

    table.table-striped > tbody > tr:hover > td {
        background-color: color(base-100);
    }
}
