.live-captions-menu {

    .captions-disabled,
    .captions-non-paid-users {
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: column;
        text-align: center;
                    // modal height - contents height - header height
        transform: translate(0%, calc(300px - 295px + 75px));

        .text {
            margin: box-model(m) 0;
        }
    }

    .captions-enabled {
        .form-group {
            margin-bottom: box-model(m);
        }

        .captions-info {
            margin-bottom: box-model(l);

            a {
                cursor: pointer;
            }
            strong {
                padding-right: 5px;
            }
            i {
                font-size: icon-size(xxs);
                margin-left: box-model(xxs);
                transform: translateY(box-model(xxxs));
                display: inline-block;
            }
        }

        .caption-service-options {
            margin-bottom: box-model(s);

            .form-group {
                margin-bottom: 0;
            }

            .input-inline {
                margin-bottom: box-model(xs);
            }
        }

        h4.headlines {
            margin-bottom: 0;
            margin-top: box-model(l);

            &.mb-xs {
                margin-bottom: box-model(xs);
            }
        }

        .session-id {
            margin-top: box-model(xs);
        }

        .virtual-captions-wraper {
            margin-bottom: box-model(xl);
        }
    }

    .form-group.grouped-rows {
        margin-bottom: box-model(xs);
    }

    .input-inline {

        display: flex;

        label[for] {
            display: inline-flex;
            margin: 0;
            margin-right: box-model(xs);
            width: box-model(l);

            span {
                line-height: initial;
                transform: translateY(box-model(xs));
            }
        }

        .input-wrapper {
            display: inline-flex;
            flex-grow: 1;
        }

        .help {
            display: none;
        }

    }

    .captions-broadcast {
        .help {
            transform: translate(box-model(xl), 0 - box-model(xxs));
        }
    }

    .in-person-captions-wraper {
        border-top: 1px solid rgb(241, 242, 244);
        padding-top: box-model(xl);
    }
}
