%base-card {
    background-color: color(base-000);
    display: block;
}

%card-with-borders {
    @extend %base-card;

    border: 1px solid color(base-300);
    border-radius: $base-border-radius;
}

.card {
    @extend %card-with-borders;

    display: inline-block;
    vertical-align: top;

    &.has-action:hover {
        background-color: color(base-100);
        cursor: pointer;

        .title {
            color: color(primary-300);
        }
    }

    &.no-media {
        @include alternate-colors('i.picture', 10%);
    }

    &.selected,
    &.selected:hover {
        border-color: color(primary-300);
        box-shadow: 0 0 0 1px color(primary-300);

        input.selection {
            opacity: 1;
        }
    }

    &.selectable {
        position: relative;
    }

    &.selectable:hover {
        input.selection {
            opacity: 1;
        }
    }

    input.selection {
        position: absolute;
        margin: box-model(m);
        z-index: 1;
        opacity: 0;
        transition: opacity .2s linear;
        height: box-model(m) + 2px; // borders
        width: box-model(m) + 2px; // borders
    }

    &.webinar {
        &.selected,
        &.selectable:hover {
            .badge {
                margin-left: box-model(xl) + box-model(xs);
            }
        }

        .badge {
            position: static;
            margin-bottom: box-model(xxs);
            transition: margin-left .2s linear;
        }

        .content-text {
            padding-top: 0;
            min-height: 0;
        }
    }

    .content-header {
        background-color: color(base-100);
        border-bottom: 1px solid color(base-300);
    }

    .content-banner {
        position: relative;
    }

    .badge {
        position: absolute;
        right: 0;
        margin: box-model(m);
        z-index: 1;
    }

    .media {
        position: relative;

        img {
            position: absolute;
        }

        i {
            border-top-left-radius: $base-border-radius;
            border-top-right-radius: $base-border-radius;
            display: block;
            background-position: top center;
            background-size: cover;
            position: relative;

            &::after {
                content: '';
                display: block;
                padding-bottom: $cards-tile-image-ratio;
            }
        }
    }

    .content-text {
        padding: box-model(m);
        min-height: box-model(xs) * 9;
    }

    .title {
        @include font-size(document);
        @include font-weight(normal);
        @include line-height(base);
        @include line-clamp(2);
        color: color(base-700);
        margin: box-model(0, 0 ,xxs);
    }

    small {
        @include line-height(xxs);
        max-height: line-height-original(xxs) * 2;
        overflow: hidden;
        display: block;

        &.sup {
            @include ellipsis;
            margin: box-model(0, 0 ,xxs);
        }
    }

    img {
        width: 100%;
    }

    a.more {
        @include font-size(s);
        margin-top: box-model(s);
        display: inline-block;
    }
}

.cards-container {
    margin-left: - box-model(m);
    margin-right: - box-model(m);
}
