[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'FontAwesome' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-fire-extinguisher {
    &:before {
        content: $icon-fire-extinguisher;
    }
}
.icon-arrow-down {
    &:before {
        content: $icon-arrow-down;
    }
}
.icon-arrow-left {
    &:before {
        content: $icon-arrow-left;
    }
}
.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}
.icon-arrow-up {
    &:before {
        content: $icon-arrow-up;
    }
}
.icon-dot-single {
    &:before {
        content: $icon-dot-single;
    }
}
.icon-cog {
    &:before {
        content: $icon-cog;
    }
}
.icon-cogs {
    &:before {
        content: $icon-cogs;
    }
}
.icon-note2 {
    &:before {
        content: $icon-note2;
    }
}
.icon-spotman {
    &:before {
        content: $icon-spotman;
    }
}
.icon-voting {
    &:before {
        content: $icon-voting;
    }
}
.icon-qna {
    &:before {
        content: $icon-qna;
    }
}
.icon-surveys {
    &:before {
        content: $icon-surveys;
    }
}
.icon-note {
    &:before {
        content: $icon-note;
    }
}
.icon-sponsors {
    &:before {
        content: $icon-sponsors;
    }
}
.icon-more-events {
    &:before {
        content: $icon-more-events;
    }
}
.icon-warning {
    &:before {
        content: $icon-warning;
    }
}
.icon-warning-sign {
    &:before {
        content: $icon-warning-sign;
    }
}
.icon-chevron-down {
    &:before {
        content: $icon-chevron-down;
    }
}
.icon-caret-down {
    &:before {
        content: $icon-caret-down;
    }
}
.icon-chevron-left {
    &:before {
        content: $icon-chevron-left;
    }
}
.icon-caret-left {
    &:before {
        content: $icon-caret-left;
    }
}
.icon-chevron-right {
    &:before {
        content: $icon-chevron-right;
    }
}
.icon-caret-right {
    &:before {
        content: $icon-caret-right;
    }
}
.icon-chevron-up {
    &:before {
        content: $icon-chevron-up;
    }
}
.icon-caret-up {
    &:before {
        content: $icon-caret-up;
    }
}
.icon-cloud {
    &:before {
        content: $icon-cloud;
    }
}
.icon-controller-jump-to-start {
    &:before {
        content: $icon-controller-jump-to-start;
    }
}
.icon-controller-next {
    &:before {
        content: $icon-controller-next;
    }
}
.icon-controller-paus {
    &:before {
        content: $icon-controller-paus;
    }
}
.icon-controller-play {
    &:before {
        content: $icon-controller-play;
    }
}
.icon-controller-record {
    &:before {
        content: $icon-controller-record;
    }
}
.icon-controller-stop {
    &:before {
        content: $icon-controller-stop;
    }
}
.icon-dots-three-horizontal {
    &:before {
        content: $icon-dots-three-horizontal;
    }
}
.icon-ellipsis-horizontal {
    &:before {
        content: $icon-ellipsis-horizontal;
    }
}
.icon-dots-three-vertical {
    &:before {
        content: $icon-dots-three-vertical;
    }
}
.icon-download {
    &:before {
        content: $icon-download;
    }
}
.icon-resize-100 {
    &:before {
        content: $icon-resize-100;
    }
}
.icon-resize-small {
    &:before {
        content: $icon-resize-small;
    }
}
.icon-resize-full-screen {
    &:before {
        content: $icon-resize-full-screen;
    }
}
.icon-fullscreen {
    &:before {
        content: $icon-fullscreen;
    }
}
.icon-select-arrows {
    &:before {
        content: $icon-select-arrows;
    }
}
.icon-trash {
    &:before {
        content: $icon-trash;
    }
}
.icon-triangle-down {
    &:before {
        content: $icon-triangle-down;
    }
}
.icon-sort-down {
    &:before {
        content: $icon-sort-down;
    }
}
.icon-triangle-left {
    &:before {
        content: $icon-triangle-left;
    }
}
.icon-triangle-right {
    &:before {
        content: $icon-triangle-right;
    }
}
.icon-triangle-up {
    &:before {
        content: $icon-triangle-up;
    }
}
.icon-sort-up {
    &:before {
        content: $icon-sort-up;
    }
}
.icon-upload {
    &:before {
        content: $icon-upload;
    }
}
.icon-upload-to-cloud {
    &:before {
        content: $icon-upload-to-cloud;
    }
}
.icon-upload-alt {
    &:before {
        content: $icon-upload-alt;
    }
}
.icon-save {
    &:before {
        content: $icon-save;
    }
}
.icon-address {
    &:before {
        content: $icon-address;
    }
}
.icon-adjust {
    &:before {
        content: $icon-adjust;
    }
}
.icon-aircraft {
    &:before {
        content: $icon-aircraft;
    }
}
.icon-archive {
    &:before {
        content: $icon-archive;
    }
}
.icon-area-graph {
    &:before {
        content: $icon-area-graph;
    }
}
.icon-attachment {
    &:before {
        content: $icon-attachment;
    }
}
.icon-back {
    &:before {
        content: $icon-back;
    }
}
.icon-bar-graph {
    &:before {
        content: $icon-bar-graph;
    }
}
.icon-bar-chart {
    &:before {
        content: $icon-bar-chart;
    }
}
.icon-bell {
    &:before {
        content: $icon-bell;
    }
}
.icon-blackboard {
    &:before {
        content: $icon-blackboard;
    }
}
.icon-block {
    &:before {
        content: $icon-block;
    }
}
.icon-ban-circle {
    &:before {
        content: $icon-ban-circle;
    }
}
.icon-book {
    &:before {
        content: $icon-book;
    }
}
.icon-box {
    &:before {
        content: $icon-box;
    }
}
.icon-briefcase {
    &:before {
        content: $icon-briefcase;
    }
}
.icon-browser {
    &:before {
        content: $icon-browser;
    }
}
.icon-list-alt {
    &:before {
        content: $icon-list-alt;
    }
}
.icon-brush {
    &:before {
        content: $icon-brush;
    }
}
.icon-bucket {
    &:before {
        content: $icon-bucket;
    }
}
.icon-cake {
    &:before {
        content: $icon-cake;
    }
}
.icon-calendar {
    &:before {
        content: $icon-calendar;
    }
}
.icon-camera {
    &:before {
        content: $icon-camera;
    }
}
.icon-ccw {
    &:before {
        content: $icon-ccw;
    }
}
.icon-chat {
    &:before {
        content: $icon-chat;
    }
}
.icon-comments {
    &:before {
        content: $icon-comments;
    }
}
.icon-comments-alt {
    &:before {
        content: $icon-comments-alt;
    }
}
.icon-comment {
    &:before {
        content: $icon-comment;
    }
}
.icon-comment-alt {
    &:before {
        content: $icon-comment-alt;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-ok {
    &:before {
        content: $icon-ok;
    }
}
.icon-circle-with-cross {
    &:before {
        content: $icon-circle-with-cross;
    }
}
.icon-remove {
    &:before {
        content: $icon-remove;
    }
}
.icon-remove-sign {
    &:before {
        content: $icon-remove-sign;
    }
}
.icon-circle-with-minus,
.icon-minus-sign {
    &:before {
        content: $icon-circle-with-minus;
    }
}
.icon-circle-with-plus,
.icon-plus-sign {
    &:before {
        content: $icon-circle-with-plus;
    }
}
.icon-circle {
    &:before {
        content: $icon-circle;
    }
}
.icon-circle-blank {
    &:before {
        content: $icon-circle-blank;
    }
}
.icon-clapperboard {
    &:before {
        content: $icon-clapperboard;
    }
}
.icon-colours {
    &:before {
        content: $icon-colours;
    }
}
.icon-compass {
    &:before {
        content: $icon-compass;
    }
}
.icon-copy {
    &:before {
        content: $icon-copy;
    }
}
.icon-credit-card {
    &:before {
        content: $icon-credit-card;
    }
}
.icon-credit {
    &:before {
        content: $icon-credit;
    }
}
.icon-cross {
    &:before {
        content: $icon-cross;
    }
}
.icon-cw {
    &:before {
        content: $icon-cw;
    }
}
.icon-cycle {
    &:before {
        content: $icon-cycle;
    }
}
.icon-refresh {
    &:before {
        content: $icon-refresh;
    }
}
.icon-database {
    &:before {
        content: $icon-database;
    }
}
.icon-direction {
    &:before {
        content: $icon-direction;
    }
}
.icon-file {
    &:before {
        content: $icon-file;
    }
}
.icon-documents {
    &:before {
        content: $icon-documents;
    }
}
.icon-drop {
    &:before {
        content: $icon-drop;
    }
}
.icon-tint {
    &:before {
        content: $icon-tint;
    }
}
.icon-edit {
    &:before {
        content: $icon-edit;
    }
}
.icon-email {
    &:before {
        content: $icon-email;
    }
}
.icon-emoji-happy {
    &:before {
        content: $icon-emoji-happy;
    }
}
.icon-emoji-neutral {
    &:before {
        content: $icon-emoji-neutral;
    }
}
.icon-emoji-sad {
    &:before {
        content: $icon-emoji-sad;
    }
}
.icon-erase {
    &:before {
        content: $icon-erase;
    }
}
.icon-eye {
    &:before {
        content: $icon-eye;
    }
}
.icon-eye-open {
    &:before {
        content: $icon-eye-open;
    }
}
.icon-feather {
    &:before {
        content: $icon-feather;
    }
}
.icon-flag {
    &:before {
        content: $icon-flag;
    }
}
.icon-flash {
    &:before {
        content: $icon-flash;
    }
}
.icon-flashlight {
    &:before {
        content: $icon-flashlight;
    }
}
.icon-folder-images {
    &:before {
        content: $icon-folder-images;
    }
}
.icon-folder-music {
    &:before {
        content: $icon-folder-music;
    }
}
.icon-folder-video {
    &:before {
        content: $icon-folder-video;
    }
}
.icon-folder {
    &:before {
        content: $icon-folder;
    }
}
.icon-folder-open {
    &:before {
        content: $icon-folder-open;
    }
}
.icon-forward {
    &:before {
        content: $icon-forward;
    }
}
.icon-redo {
    &:before {
        content: $icon-redo;
    }
}
.icon-repeat {
    &:before {
        content: $icon-repeat;
    }
}
.icon-funnel {
    &:before {
        content: $icon-funnel;
    }
}
.icon-filter {
    &:before {
        content: $icon-filter;
    }
}
.icon-game-controller {
    &:before {
        content: $icon-game-controller;
    }
}
.icon-gauge {
    &:before {
        content: $icon-gauge;
    }
}
.icon-globe {
    &:before {
        content: $icon-globe;
    }
}
.icon-graduation-cap {
    &:before {
        content: $icon-graduation-cap;
    }
}
.icon-grid {
    &:before {
        content: $icon-grid;
    }
}
.icon-th-large {
    &:before {
        content: $icon-th-large;
    }
}
.icon-hair-cross {
    &:before {
        content: $icon-hair-cross;
    }
}
.icon-hand {
    &:before {
        content: $icon-hand;
    }
}
.icon-heart-outlined {
    &:before {
        content: $icon-heart-outlined;
    }
}
.icon-heart {
    &:before {
        content: $icon-heart;
    }
}
.icon-help-with-circle {
    &:before {
        content: $icon-help-with-circle;
    }
}
.icon-question-sign {
    &:before {
        content: $icon-question-sign;
    }
}
.icon-help {
    &:before {
        content: $icon-help;
    }
}
.icon-home {
    &:before {
        content: $icon-home;
    }
}
.icon-hour-glass {
    &:before {
        content: $icon-hour-glass;
    }
}
.icon-image {
    &:before {
        content: $icon-image;
    }
}
.icon-picture {
    &:before {
        content: $icon-picture;
    }
}
.icon-images {
    &:before {
        content: $icon-images;
    }
}
.icon-infinity {
    &:before {
        content: $icon-infinity;
    }
}
.icon-info-with-circle {
    &:before {
        content: $icon-info-with-circle;
    }
}
.icon-info-sign {
    &:before {
        content: $icon-info-sign;
    }
}
.icon-info {
    &:before {
        content: $icon-info;
    }
}
.icon-key {
    &:before {
        content: $icon-key;
    }
}
.icon-keyboard {
    &:before {
        content: $icon-keyboard;
    }
}
.icon-lab-flask {
    &:before {
        content: $icon-lab-flask;
    }
}
.icon-language {
    &:before {
        content: $icon-language;
    }
}
.icon-laptop {
    &:before {
        content: $icon-laptop;
    }
}
.icon-leaf {
    &:before {
        content: $icon-leaf;
    }
}
.icon-lifebuoy {
    &:before {
        content: $icon-lifebuoy;
    }
}
.icon-light-bulb {
    &:before {
        content: $icon-light-bulb;
    }
}
.icon-lightbulb {
    &:before {
        content: $icon-lightbulb;
    }
}
.icon-light-down {
    &:before {
        content: $icon-light-down;
    }
}
.icon-light-up {
    &:before {
        content: $icon-light-up;
    }
}
.icon-line-graph {
    &:before {
        content: $icon-line-graph;
    }
}
.icon-link {
    &:before {
        content: $icon-link;
    }
}
.icon-list {
    &:before {
        content: $icon-list;
    }
}
.icon-list-ul {
    &:before {
        content: $icon-list-ul;
    }
}
.icon-list-li {
    &:before {
        content: $icon-list-li;
    }
}
.icon-location-pin {
    &:before {
        content: $icon-location-pin;
    }
}
.icon-location {
    &:before {
        content: $icon-location;
    }
}
.icon-lock-open {
    &:before {
        content: $icon-lock-open;
    }
}
.icon-lock {
    &:before {
        content: $icon-lock;
    }
}
.icon-log-out {
    &:before {
        content: $icon-log-out;
    }
}
.icon-login {
    &:before {
        content: $icon-login;
    }
}
.icon-magnet {
    &:before {
        content: $icon-magnet;
    }
}
.icon-magnifying-glass {
    &:before {
        content: $icon-magnifying-glass;
    }
}
.icon-mail {
    &:before {
        content: $icon-mail;
    }
}
.icon-envelope {
    &:before {
        content: $icon-envelope;
    }
}
.icon-envelope-alt {
    &:before {
        content: $icon-envelope-alt;
    }
}
.icon-man {
    &:before {
        content: $icon-man;
    }
}
.icon-map {
    &:before {
        content: $icon-map;
    }
}
.icon-mask {
    &:before {
        content: $icon-mask;
    }
}
.icon-medal {
    &:before {
        content: $icon-medal;
    }
}
.icon-megaphone {
    &:before {
        content: $icon-megaphone;
    }
}
.icon-bullhorn {
    &:before {
        content: $icon-bullhorn;
    }
}
.icon-menu {
    &:before {
        content: $icon-menu;
    }
}
.icon-reorder {
    &:before {
        content: $icon-reorder;
    }
}
.icon-message {
    &:before {
        content: $icon-message;
    }
}
.icon-mic {
    &:before {
        content: $icon-mic;
    }
}
.icon-microphone {
    &:before {
        content: $icon-microphone;
    }
}
.icon-minus {
    &:before {
        content: $icon-minus;
    }
}
.icon-mobile-phone {
    &:before {
        content: $icon-mobile-phone;
    }
}
.icon-modern-mic {
    &:before {
        content: $icon-modern-mic;
    }
}
.icon-mouse {
    &:before {
        content: $icon-mouse;
    }
}
.icon-network {
    &:before {
        content: $icon-network;
    }
}
.icon-new-message {
    &:before {
        content: $icon-new-message;
    }
}
.icon-new {
    &:before {
        content: $icon-new;
    }
}
.icon-news {
    &:before {
        content: $icon-news;
    }
}
.icon-notification {
    &:before {
        content: $icon-notification;
    }
}
.icon-old-mobile {
    &:before {
        content: $icon-old-mobile;
    }
}
.icon-old-phone {
    &:before {
        content: $icon-old-phone;
    }
}
.icon-palette {
    &:before {
        content: $icon-palette;
    }
}
.icon-paper-plane {
    &:before {
        content: $icon-paper-plane;
    }
}
.icon-pencil {
    &:before {
        content: $icon-pencil;
    }
}
.icon-phone {
    &:before {
        content: $icon-phone;
    }
}
.icon-pie-chart {
    &:before {
        content: $icon-pie-chart;
    }
}
.icon-pin {
    &:before {
        content: $icon-pin;
    }
}
.icon-pushpin {
    &:before {
        content: $icon-pushpin;
    }
}
.icon-plus {
    &:before {
        content: $icon-plus;
    }
}
.icon-popup {
    &:before {
        content: $icon-popup;
    }
}
.icon-power-plug {
    &:before {
        content: $icon-power-plug;
    }
}
.icon-price-tag {
    &:before {
        content: $icon-price-tag;
    }
}
.icon-print {
    &:before {
        content: $icon-print;
    }
}
.icon-progress-empty {
    &:before {
        content: $icon-progress-empty;
    }
}
.icon-progress-full {
    &:before {
        content: $icon-progress-full;
    }
}
.icon-progress-one {
    &:before {
        content: $icon-progress-one;
    }
}
.icon-progress-two {
    &:before {
        content: $icon-progress-two;
    }
}
.icon-quote {
    &:before {
        content: $icon-quote;
    }
}
.icon-reply-all {
    &:before {
        content: $icon-reply-all;
    }
}
.icon-reply {
    &:before {
        content: $icon-reply;
    }
}
.icon-undo {
    &:before {
        content: $icon-undo;
    }
}
.icon-rocket {
    &:before {
        content: $icon-rocket;
    }
}
.icon-round-brush {
    &:before {
        content: $icon-round-brush;
    }
}
.icon-feed {
    &:before {
        content: $icon-feed;
    }
}
.icon-ruler {
    &:before {
        content: $icon-ruler;
    }
}
.icon-scissors {
    &:before {
        content: $icon-scissors;
    }
}
.icon-share {
    &:before {
        content: $icon-share;
    }
}
.icon-shield {
    &:before {
        content: $icon-shield;
    }
}
.icon-shop {
    &:before {
        content: $icon-shop;
    }
}
.icon-shopping-basket {
    &:before {
        content: $icon-shopping-basket;
    }
}
.icon-shopping-cart {
    &:before {
        content: $icon-shopping-cart;
    }
}
.icon-shuffle {
    &:before {
        content: $icon-shuffle;
    }
}
.icon-signal {
    &:before {
        content: $icon-signal;
    }
}
.icon-sound-mix {
    &:before {
        content: $icon-sound-mix;
    }
}
.icon-sports-club {
    &:before {
        content: $icon-sports-club;
    }
}
.icon-table {
    &:before {
        content: $icon-table;
    }
}
.icon-star-outlined {
    &:before {
        content: $icon-star-outlined;
    }
}
.icon-star {
    &:before {
        content: $icon-star;
    }
}
.icon-stopwatch {
    &:before {
        content: $icon-stopwatch;
    }
}
.icon-suitcase {
    &:before {
        content: $icon-suitcase;
    }
}
.icon-swap {
    &:before {
        content: $icon-swap;
    }
}
.icon-switch {
    &:before {
        content: $icon-switch;
    }
}
.icon-off {
    &:before {
        content: $icon-off;
    }
}
.icon-tablet {
    &:before {
        content: $icon-tablet;
    }
}
.icon-thermometer {
    &:before {
        content: $icon-thermometer;
    }
}
.icon-thumbs-down {
    &:before {
        content: $icon-thumbs-down;
    }
}
.icon-thumbs-up {
    &:before {
        content: $icon-thumbs-up;
    }
}
.icon-thunder-cloud {
    &:before {
        content: $icon-thunder-cloud;
    }
}
.icon-ticket {
    &:before {
        content: $icon-ticket;
    }
}
.icon-time-slot {
    &:before {
        content: $icon-time-slot;
    }
}
.icon-tools {
    &:before {
        content: $icon-tools;
    }
}
.icon-wrench {
    &:before {
        content: $icon-wrench;
    }
}
.icon-trophy {
    &:before {
        content: $icon-trophy;
    }
}
.icon-tv {
    &:before {
        content: $icon-tv;
    }
}
.icon-desktop {
    &:before {
        content: $icon-desktop;
    }
}
.icon-typing {
    &:before {
        content: $icon-typing;
    }
}
.icon-user {
    &:before {
        content: $icon-user;
    }
}
.icon-users {
    &:before {
        content: $icon-users;
    }
}
.icon-group {
    &:before {
        content: $icon-group;
    }
}
.icon-v-card {
    &:before {
        content: $icon-v-card;
    }
}
.icon-video {
    &:before {
        content: $icon-video;
    }
}
.icon-vinyl {
    &:before {
        content: $icon-vinyl;
    }
}
.icon-voicemail {
    &:before {
        content: $icon-voicemail;
    }
}
.icon-wallet {
    &:before {
        content: $icon-wallet;
    }
}
.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}
.icon-flickr {
    &:before {
        content: $icon-flickr;
    }
}
.icon-google {
    &:before {
        content: $icon-google;
    }
}
.icon-instagram {
    &:before {
        content: $icon-instagram;
    }
}
.icon-linkedin-with-circle {
    &:before {
        content: $icon-linkedin-with-circle;
    }
}
.icon-twitter {
    &:before {
        content: $icon-twitter;
    }
}
.icon-youtube {
    &:before {
        content: $icon-youtube;
    }
}
.icon-eye-with-line {
    &:before {
        content: $icon-eye-with-line;
    }
}
.icon-eye-close {
    &:before {
        content: $icon-eye-close;
    }
}
.icon-qrcode {
    &:before {
        content: $icon-qrcode;
    }
}
.icon-font {
    &:before {
        content: $icon-font;
    }
}
.icon-bold {
    &:before {
        content: $icon-bold;
    }
}
.icon-italic {
    &:before {
        content: $icon-italic;
    }
}
.icon-align-left {
    &:before {
        content: $icon-align-left;
    }
}
.icon-align-center {
    &:before {
        content: $icon-align-center;
    }
}
.icon-align-right {
    &:before {
        content: $icon-align-right;
    }
}
.icon-align-justify {
    &:before {
        content: $icon-align-justify;
    }
}
.icon-editor-list-ul {
    &:before {
        content: $icon-editor-list-ul;
    }
}
.icon-editor-list-ol {
    &:before {
        content: $icon-editor-list-ol;
    }
}
.icon-list-ol {
    &:before {
        content: $icon-list-ol;
    }
}
.icon-strikethrough {
    &:before {
        content: $icon-strikethrough;
    }
}
.icon-underline {
    &:before {
        content: $icon-underline;
    }
}
.icon-superscript {
    &:before {
        content: $icon-superscript;
    }
}
.icon-subscript {
    &:before {
        content: $icon-subscript;
    }
}
.icon-puzzle-piece {
    &:before {
        content: $icon-puzzle-piece;
    }
}
.icon-loader {
    &:before {
        content: $icon-loader;
    }
}
.icon-spinner {
    &:before {
        content: $icon-spinner;
    }
}
