.chart-widget {
    svg {
        position: relative;
        z-index: 1;
    }

    .title {
        @include font-size(document);
        @include font-weight(semibold);

        color: color(base-600);
        margin-bottom: box-model(m);

        i {
            vertical-align: middle;
            margin-right: box-model(s);
        }

        small {
            margin-left: box-model(s);
        }

        a {
            @include font-weight(normal);

            line-height: 1;
            display: inline-block;
            padding: box-model(0, xs);
            border-right: 1px solid color(primary-300);

            &.active {
                text-decoration: underline;
                cursor: default;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    .chart-wrapper {
        color: color(base-300);
        min-height: box-model(xs) * 30;
        position: relative;

        .loader > .loading {
            left: 50%;
        }

        .tick {
            color: color(base-500);
        }
    }

    svg {
        display: block;
    }

    .loaded {
        > .chart {
            opacity: 0;
            transition: opacity .2s linear;
        }

        &.in > .chart {
            opacity: 1;
        }
    }

    .point-tooltip {
        transition: opacity .2s ease-in-out;
    }

    .legend {
        @include font-size(xs);
        @include font-weight(semibold);
        @include line-height(xxs);

        float: right;
        display: flex;
        gap: box-model(xs);
    }

    .pill {
        display: inline-block;
        border: 1px solid color(base-300);
        padding: box-model(xxs, xs);
        border-radius: box-model(xxs);
        cursor: pointer;
        transition: $base-background-color-transition;

        &.active {
            background-color: color(base-300);
        }

        &:hover {
            background-color: colorAlpha(base-500, .5);
        }

        &:active {
            background-color: colorAlpha(base-500, .7);
        }

        .color {
            border-radius: 100%;
            height: box-model(xs);
            width: box-model(xs);
            margin-right: box-model(xs);
            display: inline-block;
            vertical-align: middle;
        }

        .icon {
            display: inline-block;
            margin-right: box-model(xs);

            img {
                vertical-align: middle;
                margin-top: box-model(-xxxs);
            }
        }
    }
}
