.bstg1 {
    .org-package-manager {
        .table-global-search input{
            position: relative;
            top: 0;
        }

        .search-bar {
            line-height: 30px;
        }

        .table {
            margin-top: 18px;

            thead {
                border-top: 0;
            }
        }

        .latest-version {
            display: inline-block;
            min-width: 60px;
        }

        .badge {
            border-radius: 4px;
            font-size: 11px;
            font-weight: 500;
        }

        .badge-deprecated {
            color: #252533;
            background-color: transparentize(#9b9b9b, 0.8);
        }

        .badge-draft {
            color: #9b9b9b;
            background-color: transparentize(#9b9b9b, 0.8);
        }

        .badge-new-version {
            color: #ff9500;
            background-color: transparentize(#ff9500, 0.8);
        }
    }

    .org-package-manager-modal {

        .form-generator-array-field-container {
            select {
                width: 90%;
            }

            button {
                position: relative;
            }
        }

        .badge {
            border-radius: 4px;
            font-size: 11px;
            font-weight: 500;
        }

        .badge-draft {
            color: #9b9b9b;
            background-color: transparentize(#9b9b9b, 0.8);
        }

        .badge-new-version {
            color: #ff9500;
            background-color: transparentize(#ff9500, 0.8);
        }
    }
}
