.metadata-edit-choice-list {
    display: flex;
    .buttons {
        display: flex;
        flex-direction: column;
        > button,
        > a {
            margin-bottom: box-model(xxs);
            padding-left: box-model(xxs);
            padding-right: box-model(xxs);
        }
    }
    .form-group {
        margin-bottom: 0px;
        flex-grow: 2;
    }
    textarea {
        min-height: calc(box-model(h) + box-model(xl));
    }
}
