.bstg1 bs-legal-docs-picker {
    display: block;

    .item {
        display: flex;
        padding: 10px;
        border-bottom: 1px solid #f2f2f2;

        .btn.btn-danger {
            margin-left: 5px;
        }
    }

    .actions {
        border-bottom: 1px solid #f2f2f2;
        background-color: #fafafa;
        padding: 10px;

        a {
            margin-top: 5px;
        }
    }

    .new-item {
        background-color: #ffe;
    }
}
