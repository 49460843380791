.activity {
    > .metrics {
        @include grid(12);
        color: color(base-700);
    }

    .card {
        @include cols(3);

        height: calc(box-model(m) + box-model(h));
        border: solid color(base-300) 1px;
        border-top: solid color(primary-300) box-model(xxs);
        border-radius: box-model(xxs);
        padding: box-model(s);

        &.created-users {
            border-top-color: color(alt-1-300);
        }

        &.activated-users {
            border-top-color: color(warning-300);
        }
    }

    .count {
        font-size: font-size(xxm);
        font-weight: font-weight(semibold);
    }

    .label {
        font-size: font-size(base);
        margin-top: box-model(xxs);
    }

    .details {
        font-size: font-size(s);
    }

    .badge {
        position: inherit;
        margin: box-model(0, xxs, 0, 0);
        vertical-align: middle;
    }

    .percent {
        color: color(primary-500);
        background-color: color(primary-050);
    }

    .activated-users .percent {
        color: color(warning-500);
        background-color: color(warning-100);
    }
}
