// == Main manifest file
@charset "UTF-8";

@use "sass:math";

// Abstracts
@import "abstracts/functions";
@import "abstracts/variables";
@import "abstracts/mixins";
@import "abstracts/icons";

// Normalization
@import "normalize.css/normalize.css";

// Third party libraries
@import "vendor/index";


// Below all the legacy code.
// --------------------------

// - Legacy LESS build
@import "_LEGACY_/index";


// Main styles
@import "media-queries";

// Browsers fixes
@import "abstracts/browsers-fixes";
