.package-inspector {
    .changes {
        display: flex;

        > * {
            flex: 1 1 25%;
            text-align: center;
            cursor: help;
        }

        .invisible {
            visibility: hidden;
        }

        .appscript {
            @extend .badge-info;
        }

        .handler {
            @extend .badge-danger;
        }

        .backstage {
            @extend .badge-success;
        }

        .frontstage {
            @extend .badge-alt-2;
        }
    }

    .operation {
        text-align: center;
        background: color(base-300);
        border-radius: $small-border-radius;

        &.deploy,
        &.uninstall {
            background-color: color(error-300);
            color: color(base-700);
        }

        &.install {
            background-color: color(success-300);
            color: color(base-700);
        }

        &.update {
            background-color: color(warning-300);
            color: color(base-000);
        }
    }
}
