// COMPONENT ANIMATIONS
// --------------------

.fade {
  transition: opacity .15s linear;
  opacity: 0;

  &.in {
    opacity: 1;
  }
}

.collapse {
  transition: height .35s ease;
  position: relative;
  overflow: hidden;
  height: 0;

  &.in {
    height: auto;
  }
}
