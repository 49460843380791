.jt-editor {
    min-height: 29px;
    padding: 2px 12px 1px 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: inset 0 0px 1px rgba(0, 0, 0, 0.075);
}

.jt-tag {
    position: relative;
    top: 2px;
    background: none;
    border: none;
}
.jt-tag-new {
    min-height: 0;
    padding: 3px;
    &:focus {
        box-shadow: none;
    }
}

.jt-tag-new, .jt-tag-edit {
    box-shadow: none;
}
