.content-hubs-page {
    .search {
        display: flex;
        align-items: flex-start;
    }

    .search-bar {
        margin-left: auto;
    }

    .section-list {
        margin : box-model(l, 0);
    }

    .card {
        .content-text {
            display: flex;
            flex-direction: column;
            height: 100%;
            box-sizing: border-box;
            > .sub {
                margin-bottom: box-model(l);
            }
        }

        footer {
            margin-top: auto;

            > span {
                display: inline-block;
                padding: 0 box-model(xs);
            }

            .icon-rocket {
                margin-left: box-model(xs);
            }
        }
    }
    .no-data {
        text-align: center;
        display: block;
        padding-top: 10%;
        h1 {
            @include font-weight(semibold);
            @include font-size(xxl);
            @include line-height(r);
            margin: box-model(m);
        }
    }
}
