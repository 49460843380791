:fullscreen {
    .fullscreen-fixed-cover,
    .maximized-fixed-cover {
        position: fixed;
        top:  0;
        left: 0;
        width:  100%;
        height: 100%;
    }
}


.inpage-spotlet-modal iframe {
    border: none;
    width: 100%;
    height: 100%;
    min-height: box-model(xs) * 90;
}
