.live-stream-qna {
    .tabs-menu {
        .item {
            @include font-size(document);
        }
    }

    .add-question {
        .question-text {
            @include line-height(base);
            @include font-size(document);

            input {
                padding-top: box-model(xs);
                padding-bottom: 0;
                padding-left: box-model(m);
                padding-right: box-model(m);
            }
        }
    }

    .actions {
        padding-top: box-model(xxs);
        padding-bottom: box-model(xs);
        padding-left: box-model(m);
        padding-right: box-model(m);

        button {
            @include line-height(base);
            @include font-size(document);

            padding: box-model(xs, s);
        }
    }
}

.live-stream-qna-question {
    $edit-question-button-size: 24px;

    .info {
        @include font-size(base);
    }

    .edit-button,
    .dropdown {
        @include font-size(document);

        i {
            margin: 0;
            padding: 0;
            width: $edit-question-button-size;
            height: $edit-question-button-size;
            background-color: color(base-100);
            text-align: center;
            line-height: $edit-question-button-size;
            display: block;
            box-sizing: border-box;
            border-radius: math.div($base-border-radius, 2);
        }
    }

    .body {
        @include font-size(document);
        @include line-height(base);
    }

    .button {
        @include line-height(base);
        @include font-size(document);

        padding: box-model(xs);
    }
}
