.content-details-form {
    margin-bottom: box-model(l);

    .grouped-controls {
        .thumbnail-picker {
            width: 50%;
        }
    }
    .category-select {
        position: relative;
        > a {
            @include font-size(s);
            @include line-height(xxs);
            position: absolute;
            top: 0;
            right: 0;

        }
    }
}
