.collapsible-select {

    .multiselect__content-wrapper { display: none !important; }

    .multiselect.form-control.multiselect--active .toggler { transform: rotate(0); }

    .multiselect.form-control.multiselect--active.collapsed .toggler { transform: rotate(180deg); }

    .toggler {
        cursor: pointer;

        i {
            padding: box-model(xxs);
            border-radius: box-model(s);
        }
        &:hover {
            i {
                background-color: color(base-300);
            }
        }

    }

    .collapsed-count {
        display: inline-block;
        position: absolute;
        top: box-model(xxxs);
        height: #{box-model(l) + box-model(xxxs)};
        right: box-model(xl);
        background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1) 25%);
        z-index: 1;
        padding-left: box-model(xxl);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        .multiselect__tag {
            transform: translateY(-1px);
            padding-right: box-model(s);
        }
    }

    .multiselect__tags-wrap {
        overflow-y: hidden;
        transition: max-height 0.5s ease-in-out;
        max-height: #{box-model(h) * 64}; // should be enough
    }

    .collapsed {
        .multiselect__tags-wrap {
            max-height: #{box-model(l) + box-model(xxxs)}; // line-height + padding
        }
        .collapsed-count {
            opacity: 1;
        }

        .toggler { transform: rotate(180deg); }

    }

}