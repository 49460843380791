.person-display {
    .person-photo {
        margin-bottom: box-model(l);
        transition:
            background-color 0.2s linear,
            border-color 0.2s linear;

        &.dragging {
            border: dashed 1px color(primary-300);
            background-color: color(primary-050);
        }

        .picture {
            text-align: center;
        }

        img {
            border-radius: 100%;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        img, svg {
            width: box-model(xs) * 10;
            height: box-model(xs) * 10;
            object-fit: cover;
            margin-bottom: box-model(s);
        }

        .photo-controls {
            margin-bottom: box-model(m);

            button {
                cursor: pointer;
                background: none;
                border: none;
                font-weight: 500;
                color: color(primary-300);
            }

            .danger {
                color: color(error-300);
            }
        }

        .actions button:last-child {
            margin-bottom: 0;
        }

        .fp-ext-id {
            background-color: color(base-000);
            margin-top: box-model(m);
            margin-bottom: 0;
        }

        .unsubscribed {
            margin-top: box-model(m);
        }
    }

    .install-app {
        text-align: center;
    }

    .person-sessions-stats {
        ul {
            padding: 0;
        }

        li {
            list-style-type: none;
            border-bottom: solid 1px color(base-300);
            padding: box-model(xs) 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        li:last-of-type {
            border-bottom: none;
        }
    }

    .person-actions {
        .event-password {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-column-gap: box-model(s);

            pre {
                margin: 0;
                font-size: 1em;
                padding: calc(box-model(s) / 2) box-model(s);
            }
        }

        hr {
            margin: box-model(l);
        }
    }

    .expand-details {
        summary {
            grid-template-columns: auto 1rem;
        }

        i.ic-chevron-down,
        i.ic-chevron-up {
            @include font-size(xm);
            color: color(primary-300);
        }
    }

    .person-activity-feed {

        h5 {
            margin-bottom: box-model(s);
        }

        .activities {
            border-radius: $small-border-radius;
            border: 1px solid color(base-300);
            margin-bottom: box-model(l);
            overflow: hidden;
        }

        .activity {
            display: flex;
            gap: box-model(xs);
            padding: box-model(xs, s);

            &:nth-child(odd) {
                background-color: color(base-100);
            }

            i {
                margin-top: .4em; /* the usual icon hack */
            }

            p {
                flex: 1;
                margin: 0;
            }
        }

        details {
            flex: 1;

            summary {
                cursor: pointer;
                color: color(primary-300);
                list-style: none;
            }

            .table-wrapper {
                margin-top: box-model(m);
            }
        }

        span.date {
            float: right;
            color: color(base-700);
        }

        dl {
            margin-bottom: 0;
        }

        dt {
            width: 10%;
        }

        dd {
            margin-left: calc(10% + #{box-model(m)});
        }
    }
}
