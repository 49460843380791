$home-layout-editor-width: $main-menu-width;

.content-wrapper.home-editor {

    .home-editor-layouts-list {
        li {
            @extend .nav-item;
        }
    }

    .home-editor-layout {
        .layout-title {
            text-align: right;

            span {
                float: left;
            }
        }
    }

    .home-editor-editor .editors {
        display: flex;

        home-editor-layout {
            display: block;
            flex: 0 1 $home-layout-editor-width;
        }

        .home-editor-block-editor-instruction,
        home-editor-block-editor {
            width: auto;
            display: block;
            flex: 1;
        }
    }

    .top-fragment-form-panel {
        td:first-child {
            width: auto;
        }

        td:last-child {
            .controls {
                margin-left: 0;
            }

            .control-label {
                width: auto;
            }
        }
    }

    input, select {
        &[class*='span'] {
            width: 100%;
            float: none;
            margin-left: 0;
        }
    }

    .hex-color-controls > input[type="text"] {
        width: $color-picker-container-width;
    }

    .control-group .controls {
        line-height: 1;
        display: block;
    }

    .home-editor-conditions {
        .add-condition, .condition {
            line-height: 16px;

            i {
                vertical-align: middle;
            }
        }
    }


}
