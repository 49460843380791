.closed-captions {
    margin-bottom: box-model(m);
    a {
        cursor: pointer;
    }

    .badge {
        margin: 0;
    }

    .caption-languages {
        display: flex;

        .caption-language {
            @include font-size(xs);
            @include font-weight(semibold);
            @include line-height(xxs);
            margin: box-model(xxs);
            padding: box-model(0, xs);
            border-radius: $base-border-radius;
            border: solid 1px color(base-300);

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
