polling-interactivity {
    margin-left: 0 !important;

    .live-polling-selector, .poll {
        .title.title { // double selector hack. aggressive targeting
            @include font-size(base);
        }

        .span2, .span3, .span9, .span10 {
            width: auto;
        }

        .span9, .span10 {
            flex: 1;
        }

        .span2 {
            flex: 0.5 0.5;
            align-self: flex-end;
            margin-bottom: box-model(xs);
        }

    }
}

.metadata-manager-view polling-interactivity {
    background: transparent;

    legend.interactivity-section-header {
        position: static;
    }
}
