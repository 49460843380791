.content-wrapper.analytics {
    .stats-filtering {
        margin-bottom: box-model(m);

        select {
            display: inline-block;
            min-width: 200px;
        }
    }

    fieldset.stats-heading legend {
        margin-bottom: 27px !important; // ported from package
    }

    .span2.teaser-picto {
        @extend %card-with-borders;
        margin-left: box-model(m);
        position: relative;

        &:hover {
            cursor: default;
        }

        .stats-title {
            margin: 0;
        }

        .picto:before {
            margin: box-model(l, m, m, m) !important;
            font-size: 38px;
        }

        .title {
            margin-top: box-model(m);
        }
    }

    .metrics--widget {
        @extend %card-with-borders;
        position: relative;
        box-sizing: border-box; // Because they also have span* classes
    }

    .row-fluid.header {
        display: none;
    }

    .title {
        @include font-size(document);
        @include font-weight(semibold);
        @include line-height(base);
        margin: box-model(m);
    }

    .person-stats-tops {
        margin-top: box-model(l);
    }

    .stats-frequency {
        border-top-width: 1px!important;
    }

    .person-stats-tops,
    .person-stats-features,
    .person-stats-flow {
        margin-top: box-model(xl);
    }

    .well,
    .widget-heading {
        margin-top: box-model(m);
        margin-bottom: box-model(m);
    }

    .highcharts-background {
        fill: white;
    }

    .well {
        button.btn {
            @extend .btn-primary;
        }
    }

    .tooltip {
        min-width: 200px;
    }

    .highcharts-container {
        max-width: 100%;
    }

    .configuration-container {
        bottom: 0 !important;
    }

    .date-limits-waiting {
        padding: 0 !important;
    }
}
