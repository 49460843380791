.content-wrapper {
    min-width: 0;
}

// Keep the content stick to left
body #privileged-content-wrapper {
    margin-left: $main-menu-minimized-width;
}

// Switch off the grid system
.row, .row-fluid {
    [class^="span"], [class*=" span"] {
        float: none;
        width: auto;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: box-model(xl);
    }
}
