/*
  * Container *
  Explaining some of the CSS:
  # background-color: #FFFFFF; | To simulate an input box - in case background of the whole page is different
*/
.jt-editor {
    padding: 6px 12px 5px 12px;
    display: block;
    height: auto;

    vertical-align: middle;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    border: 1px solid #BFBFBF;
    border-radius: 2px;
    cursor: text;
    background-color: #FFFFFF;
}

.jt-editor.focused-true {
    border-color: #66AFE9;
    outline: 0;
}

/* Tag */
.jt-tag {
    background: #DEE7F8;
    border: 1px solid #949494;
    padding: 0px 0px 20px 2px;
    cursor: default;

    display: inline-block;
    border-radius: 2px 3px 3px 2px;
    height: 22px;
}

/* Because bootstrap uses it and we don't want that if bootstrap is not included to look different */
.jt-tag {
    box-sizing: border-box;
}

.jt-tag:hover {
    border-color: #BCBCBC;
}

/* Value inside jt-tag */
.jt-tag .value {
    padding-left: 4px;
}

/* Tag when active */
.jt-tag.active-true {
    border-color: rgba(82, 168, 236, 0.8);
}

/* Tag remove button ('x') */
.jt-tag .remove-button {
    cursor: pointer;
    padding-right: 4px;
}

.jt-tag .remove-button:hover {
    font-weight: bold;
}

/* New tag input & Edit tag input */
.jt-tag-new,
.jt-tag-edit {
    border: none;
    outline: 0px;
    min-width: 50px;
    /* Will keep autogrow from lowering width more than 60 */
}

/* New tag input & Edit tag input & Tag */
.jt-tag-new,
.jt-tag-edit,
.jt-tag {
    margin: 1px 4px 1px 1px;
}

/* Should not be displayed, only used to capture keydown */
.jt-fake-input {
    float: left;
    position: absolute;
    left: -10000px;
    width: 1px;
    border: 0px;
}
