.webinar-registration-page-settings {
    @include line-height(base);
    @include font-size(base);

    header,
    footer,
    .field-item {
        display: flex;
        align-items: center;
        padding: box-model(xs);
        border-top: 1px solid color(base-300);
        border-bottom: 1px solid transparent;
        border-left: 1px solid color(base-300);
        border-right: 1px solid color(base-300);
    }

    header {
        @include font-weight(semibold);
        border-radius: $large-border-radius $large-border-radius 0px 0px;

        .col-field {
            margin-left: box-model(l);
        }
    }

    footer {
        border-bottom: 1px solid color(base-300);
        border-radius: 0px 0px $large-border-radius $large-border-radius;

        .footer-item {
            margin-left: box-model(xxs);
            margin-right: box-model(xxs);
            position: relative;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .field-item {
        height: box-model(xl);
        cursor: move;

        &:hover {
            border: 1px solid color(primary-300);
            border-radius: $large-border-radius;
            background: color(primary-100);

            .remove {
                display: block;
            }
        }
    }

    .col-drag {
        margin-right: box-model(xs);
        width: box-model(m);
    }

    .col-field {
        width: box-model(xs) * 39;
    }

    .col-required {
        flex: 1 1 auto;

        .checkbox {
            margin-bottom: 0;
        }
    }

    .remove {
        display: none;
    }

    .button-and-dropdown {
        @include line-height(xs);
        padding: math.div(box-model(s), 2) box-model(s);
    }

    // Custom style fixes for the dropbown-menu component to add a field
    .add-field-menu {
        .btn-ctx-icon .ic-plus {
            vertical-align: sub;
        }

        .menu {
            margin-left: $button-max-height;
        }
    }
}
