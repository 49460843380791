a[ng-click] {
    cursor: pointer;
}

.form-control {

    &.ng-invalid:not(.ng-untouched) {
        @extend .error;
    }

    &.ng-untouched ~ .error-msg {
        display: none;
    }
}

.external{
    &.ng-invalid:not(.ng-untouched) {
        .select2-choice, .choose {
            @extend .error;
        }
    }
}

.listing-tools,
bs-call-to-actions,
.bg-export-fp-type,
bs-batch-file-management,
.action-control-group {
    display: inline-block;
}

.action-buttons .vue.upload-button {
    display: inline-block;
}

// --- Drag and droppy stuff

i.reorder-handle {
    cursor: move;
}

.ng-repeat-reorder-parent {
    padding-top: 1px;
    padding-bottom: 1px;
    position: relative;

    .dragging {
        background-color: color(base-000);
        border: 0;
    }
}

[ng-repeat-reorder].dragging {
    z-index: 11;
    cursor: move;
    position: absolute !important;
    left: 0;
    right: 0;
    display: block;

    td {
        border-top-color: transparent;
        border-bottom-color: transparent;
    }

    .hide-while-dragging {
        display: none;
    }
}

.form-group.dragging td {
    border: 1px solid #cccccc;
}

.form-group.dragging-after td {
    background-color: green;
    border-top: 1px solid #cccccc;
}

.form-group.dragging-before td {
    background-color: red;
}

.active-drag-below {
    user-select: none;

    > *:not(.dragging) {
        opacity: 0.6;
    }
}

ng-form {
    display: block;

    > .btn {
        display: block;
        width: 100%;
        margin: 0;
    }
}
