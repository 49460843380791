.scheduler-view {
    height: 100%;

    .resource {
        @include ellipsis;
        text-align: left;

        a {
            @include font-weight(semibold);
            color: color(base-700);
        }

        i {
            @include icon-size(xxs, 65);
            vertical-align: middle;
            margin-right: box-model(xs);
            border: 1px solid color(base-300);
            border-radius: box-model(m);
            display: inline-block;
            margin-top: box-model(-xxs);
        }
    }

    .avatar {
        @include icon-size(xxs);
        @include font-size(xs);
        margin-right: box-model(xs);
        margin-top: box-model(-xxs);
        border: 1px solid light-color(base-000);
        border-radius: box-model(l);
        color: light-color(base-000);
        position: relative;
        overflow: hidden;
        vertical-align: middle;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        span {
            display: block;
        }
    }

    .event-card {
        @include font-size(base);
        @include line-height(xs);

        background-color: color(alt-1-100);
        border-radius: $base-border-radius;
        border: 1px solid color(alt-1-100);
        color: color(base-700);
        padding: box-model(xxs, xs);
        box-sizing: border-box;
        height: 100%;
        overflow: hidden;
        box-shadow: $base-box-shadow;
        margin: box-model(0, 1px);

        &.yes {
            border: 1px solid color(primary-500);
            background-color: color(primary-050);
        }

        &.maybe {
            border: 1px solid color(primary-300);
            background-color: color(base-000);
        }

        &.no {
            border: 1px solid color(base-500);
            background-color: color(base-100);
        }

        .details {
            @include font-weight(semibold);
            @include line-height(s);
        }

        // short events 5-10 minutes
        @container event-card-container (height < 20px) {
            .details {
                margin-top: box-model(-xs);
            }
        }

        // events <20 minutes - to short to display time
        @container event-card-container (height < 40px) {
            .time .acceptance {
                visibility: hidden;
            }
        }

        .badge {
            margin: 0;
        }

        .title {
            @include ellipsis;
            display: block;
        }

        .time {
            @include font-size(s);
            @include line-height(s);
            @include font-weight(normal);
            @include ellipsis;
            display: block;
        }

        .acceptance {
            @include font-size(s);
            @include line-height(xs);
            @include ellipsis;

            i {
                @include icon-size(xxs);
                margin-right: box-model(xs);
                vertical-align: middle;
                border-radius: box-model(xxxs);
                color: color(base-000);

                &.yes {
                    background-color: color(success-500);
                }

                &.pending {
                    background-color: color(alert-300);
                }

                &.no {
                    background-color: color(error-300);
                }
            }
        }
    }

    /// Customization for the mobiscroll calendar

    .mbsc-schedule-event {
        container-name: event-card-container;
        container-type: size;
    }

    .mbsc-schedule-resource {
        @include alternate-colors('.resource.person .avatar', 0%);
    }

    .mbsc-calendar-wrapper {
        position: absolute;
        z-index: 4;

        .mbsc-calendar-controls {
            padding: 0;
            min-height: 0;

            .mbsc-calendar-title, .mbsc-button {
                @include font-size(base);
                @include line-height(base);
            }

            .mbsc-icon {
                width: icon-size(xxs);
                height: icon-size(xxs);
            }
        }
    }

    .mbsc-schedule-time-wrapper,
    .mbsc-schedule-item {
        height: box-model(xl) + box-model(xxs); // 36px
    }

    .mbsc-ios.mbsc-schedule-date-header {
        border-color: transparent;
    }

    .mbsc-ios.mbsc-schedule-invalid {
        background-image: image-url('disabled-slot-bg.svg');
        background-repeat: repeat;
        background-color: transparent;
    }

    .mbsc-ios.mbsc-schedule-time {
        color: color(base-500);
    }

    .mbsc-ios.mbsc-schedule-time-indicator {
        border-color: color(error-300);
    }

    .mbsc-ios .mbsc-schedule-time-indicator-time-x {
        color: color(error-300);
        border: 1px solid color(error-300);
        background-color: colorAlpha(error-300, .1);
        border-radius: box-model(m);
    }

    .mbsc-schedule-col-width {
        min-width: box-model(l) * 10;
    }


    .mbsc-popup-body-round, .mbsc-popup-body-center, .mbsc-popup-body-anchored {
        box-shadow: $base-box-shadow;
    }

    .mbsc-popup-arrow-wrapper {
        display: none;
    }

    .mbsc-list-item {
        padding: box-model(xxs, m);

        &::before, &::after {
            display: none;
        }

        &:first-child {
            padding-top: box-model(m);
        }

        &:last-child {
            padding-bottom: box-model(m);
        }

        .mbsc-hover {
            background-color: color(base-000);
        }

        .meeting {
            box-shadow: none;
        }
    }

}

.theme-dark .scheduler-view {
    .mbsc-ios {
        &.mbsc-schedule-invalid {
            opacity: .2;
        }

        &.mbsc-schedule-date-header,
        &.mbsc-schedule-header,
        &.mbsc-schedule-header-item,
        &.mbsc-eventcalendar .mbsc-calendar-header,
        &.mbsc-eventcalendar .mbsc-calendar-week-days {
            background-color: color(base-000);
        }
    }
}
