// PAGER
// -----

.pager {
    margin-left: 0;
    margin-bottom: $baseLineHeight;
    list-style: none;
    text-align: center;
    @include clearfix();
}
.pager li {
    display: inline;
}
.pager .next a {
    float: right;
}
.pager .previous a {
    float: left;
}
