$semiheader-indirect-height: 18px;

.fsa-settings-container {
    $fsa-quill-editor-btn-line-height: box-model(m);

    .form-generator-field-label, .form-generator-field-value {
        padding: box-model(xs, m);
    }

    .header-link {
        float: right;
        color: color(base-500);
        text-decoration: none;

        &:hover {
            color: color(base-700);
            text-decoration: none;
        }
    }

    h4 {
        @include font-weight(semibold);
        border: 0;
    }

    .btn-group .btn {
        margin: 0;
    }
}

.fsa-page {
    overflow: auto;
}

fsa-toolbar .fsa-toolbar {
    margin: 0;

    .alert {
        height: auto !important;
        line-height: box-model(xl);
        padding: box-model(0, xs) !important;
    }
}

.fsa-link-list-preview-item {
    height: auto !important;
}

.fsa-frontstage-architect {
    .fsa-components-list, .fsa-editor, .fsa-settings {

        @include inner-page-height(height, $indirect-tabnav-menu-height + $semiheader-indirect-height + box-model(l));

        max-height: none;

        .header-link {
            float: left;
            width: 32px;
            text-align: center;
            height: 32px;
            /* margin-top: 4px; */
            line-height: 32px;
            margin-left: -12px;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .card-block {
        padding: box-model(m);
    }

    .card-header, .card-footer {
        padding: box-model(xs, m);
        background-color: color(base-100);
    }

    .card-header {
        border-bottom: 1px solid color(base-300);
    }

    .advanced-fstg-options {
        margin-top: box-model(m);
    }
}

.fsa-page-block-with-preview .fsa-page-block-title {
    margin-bottom: -34px !important;
}

.fsa-page-block-selected, .fsa-page-block-selected:hover {
    outline: none !important;
    box-shadow: 0 0 0 2px color(primary-300) !important;
}

.fsa-person-bust-preview {
    height: auto !important;
}

.fsa-page-block-preview {
    @include clearfix;
}

.fsa-components-list.fsa-collapsed {
    overflow: hidden;

    .card-title {
        @include clearfix;
    }

    .card-block .card-title .header-link {
        margin-top: 0 !important;
    }


    fsa-blocks-defs-list {
        @include inner-page-height(height, $indirect-tabnav-menu-height + $semiheader-indirect-height + box-model(l));
        width: 65px;
        display: block;
        overflow: auto;
    }

}
