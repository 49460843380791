.live-stream-polling {
    height: 100%;
    background-color: color(base-100);
    display: flex;
    flex-direction: column;
    position: relative;

    .list-actions {
        padding: box-model(xs);

        .btn-row {
            display: flex;
        }

        .btn {
            width: 100%;
        }

        >*:not(:last-child) {
            margin-bottom: box-model(xs);
        }
    }

    .new-poll {
        color: color(base-000);

        .new-poll-select {
            &.btn {
                width: 100%;
            }

            .multiselect {

                .multiselect__content-wrapper {
                    width: 100%;
                    transform: unset;
                }
            }
        }
    }

    .new-poll-select {
        overflow: unset;

        &.btn {
            padding: 0;
        }

        .multiselect {
            background-color: transparent;
            border: none;

            .multiselect__content-wrapper {
                width: #{box-model(xxl) * 4};
                border: 0px;
                box-shadow: 0px box-model(xxxs) box-model(s) rgba(0, 0, 0, 0.1);
            }

            .multiselect__element {
                border: 1px solid color(base-100);
                border-collapse: collapse;
            }

            .multiselect__option {
                text-align: center;
            }

            i.multiselect-left-icon {
                color: color(primary-300);
                font-size: icon-size(xxs);
                margin-right: box-model(xs);
            }

            .multiselect__tags {
                background-color: transparent;
                border: none;
                padding: 0;

                .multiselect__placeholder {
                    color: color(base-000);
                    text-align: center;
                }
            }
        }

        .toggler {
            display: none;
        }
    }

    .no-polls {
        background-color: color(base-100);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .content {
            width: 200px;
            text-align: center;

            .icon {
                padding-bottom: box-model(xs);
            }

            i {
                font-size: icon-size(xs);
            }
        }

        .new-poll-select {
            .multiselect__content-wrapper {
                left: - box-model(xl);
            }
        }
    }

    .polls-list {
        padding: box-model(xs);
        height: 100%;
        overflow: auto;

        & .draggable-item .poll:hover .drag-overlay {
            opacity: 1;
        }

        .poll-card {
            position: relative;
            display: flex;

            .drag-icon-container {
                flex: 0 0 auto;
                padding-right: box-model(xs);
                display: flex;
                flex-direction: column;
                justify-content: center;

                i.icon-reorder {
                    cursor: pointer;
                }
            }

            .targeting-check {
                margin: auto;
            }
        }

        .poll {
            position: relative;
            padding: box-model(xs);
            background-color: color(base-000);
            border: 1px solid color(base-000);
            border-radius: $base-border-radius;
            margin-bottom: box-model(xs);
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            text-align: left;

            .poll-question-text {
                font-weight: 500;
                word-break: normal;
                overflow-wrap: anywhere;
            }

            .drag-overlay {
                display: flex;
                position: absolute;
                justify-content: center;
                align-items: center;
                z-index: 1;
                bottom: 0;
                top: 0;
                opacity: 0;
                margin-left: -#{box-model(xs)};
                transition: opacity .15s ease-in-out;
                color: color(base-000);
                padding: box-model(xs);
                background: colorAlpha(base-700, 0.65);
                width: 100%;
                box-sizing: border-box;
                border-radius: box-model(xxs);
            }
        }

        .poll:not(.disabled):hover {
            border: 1px solid color(primary-300);
        }

        .poll-banner {
            position: relative;
            padding-bottom: box-model(xs);

            .badge {
                position: absolute;
                top: 0;
                right: 0;
                margin: 0;

                &.badge-info-new-line {
                    position: relative;
                }
            }
        }

        .poll-type {
            color: color(primary-300);
            font-weight: 500;
            display: flex;
            align-items: center;

            i {
                color: color(base-700);
            }
        }
    }

    .back-to-list {
        padding: box-model(m, m, xs);
        display: flex;
        align-items: center;

        a {
            @include font-size(s);
            @include font-weight(semibold);

            color: color(base-700);
            flex-grow: 1;
        }

        .has-action {
            i {
                margin-right: box-model(xxs);
                vertical-align: middle;
            }
        }

        .action {
            font-size: icon-size(xxs);
            margin-left: box-model(xxs);
            padding: box-model(xs);
            border-radius: 50%;

            &:hover {
                background-color: color(base-300);
            }
            cursor: pointer;

            .delete {
                color: color(error-300)
            }
        }
    }

    .pro-tip {
        @include font-size(s);
        @include line-height(document);
        position: relative;
        padding: box-model(xs);
        background-color: color(base-000);
        border-radius: $base-border-radius;
        margin-bottom: box-model(s);
        color: color(base-600);

        .title {
            @include font-weight(semibold);
            color: color(primary-300);
            margin-bottom: box-model(xxs);

            i {
                margin-right: box-model(xxs);
            }
        }

        .close {
            position: absolute;
            top: box-model(xs);
            right: box-model(xs);
            width: box-model(m);
            height: box-model(m);
            background-color: color(base-100);
            margin: 0;

            &::after {
                // specific to the content
                font-size: 16px;
                line-height: 14px;
            }
        }
    }

    .pro-tip-message {
        @include font-size(s);
        @include font-weight(semibold);
        display: flex;
        position: relative;
        color: color(primary-300);

        .loader {
            min-height: 0;
            width: box-model(m);
            height: 100%;

            > .loading {
                left: box-model(xs);
            }
        }

        .text {
            flex: auto;
            padding-left: box-model(xs);
        }
    }

    .create-poll,
    .view-poll,
    .edit-poll {
        padding: box-model(0, m, xs);
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        h5 {
            margin-bottom: box-model(m);
        }

        .form,
        .content {
            flex: 1 1 auto;
            overflow: auto;
            padding-bottom: 8px;
        }

        > .actions {
            background-color: white;
            border-top: 1px solid color(base-300);
            padding: box-model(xs, m);
            margin: 0 box-model(m) * -1 box-model(xs) * -1;
            display: flex;
            flex-direction: column;
            gap: box-model(s);

            .btn {
                width: 100%;
            }
        }

        .word-cloud-color-list {
            margin-top: box-model(m);
        }
    }

    .create-poll,
    .edit-poll {
        .poll-form-word-cloud {
            margin-bottom: box-model(l);
        }

        .poll-form-countdown {
            margin-bottom: box-model(m);
        }

        .poll-form-countdown-duration {
            display: flex;
            flex-direction: column;
            margin-top: box-model(m);
            margin-bottom: box-model(m);

            .input-fields {
                display: flex;
                flex-direction: row;
                gap: box-model(xs);
                width: 100%;

                .suffixed-input {
                    flex: 1 1 auto;
                    margin-bottom: 0;

                    .input-wrapper {
                        box-sizing: border-box;
                        width: 100%;
                    }
                }
            }

            .help.error {
                margin-top: box-model(xs);
            }
        }

        .poll-form-countdown-follow {
            .control-label,
            label.radio {
                line-height: box-model(l);
            }

            .radiogroup {
                padding-left: box-model(xs);
            }
        }

        .show-results-live .toggler.inline-tooltip {
            display: flex;

            > span {
                flex: 0 0 box-model(xl);
            }

            div {
                display: flex;
                flex: 1;
            }
            i {
                margin-left: box-model(xs);
                margin-top: box-model(xxs);
            }
        }
    }

    .view-poll {
        .general-settings {
            padding: 12px 0;
        }

        .smvp-word-cloud-wrapper {
            height: 320px;
            margin-bottom: 24px;
            background-color: white;
        }

        .word-cloud-vote-list {
            margin-bottom: 24px;
        }

        .close-voting-select {
            overflow: unset;

            &.btn {
                padding: 0;
                width: 100%;
            }

            .multiselect {
                background-color: transparent;
                border: none;

                .multiselect__content-wrapper {
                    border: 0px;
                    box-shadow: 0px box-model(xxxs) box-model(s) rgba(0, 0, 0, 0.1);
                }

                .multiselect__element {
                    border: 1px solid color(base-100);
                    border-collapse: collapse;
                }

                .multiselect__option {
                    text-align: center;
                }

                i.multiselect-left-icon {
                    color: color(primary-300);
                    font-size: icon-size(xxs);
                    margin-right: box-model(xs);
                }

                .multiselect__tags {
                    background-color: transparent;
                    border: none;
                    padding: 0;

                    .multiselect__placeholder {
                        color: color(base-000);
                        text-align: center;
                    }
                }

                i.ic-corner-up-right, i.ic-eye {
                    color: color(success-300)
                }
            }

            .toggler {
                display: none;
            }
        }
    }

    .word-cloud-view {
        .word-cloud-top-bar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 24px;
            padding: 0 8px;
            background-color: #F7F7F8;
            border-radius: 8px 8px 0px 0px;
        }

        .zoom-button {
            padding: 0;
            height: 16px;
            font-size: 16px;
            font-weight: 600;
            color: color(primary-300);
            background-color: transparent;
            border-style: none;
            cursor: pointer;

            &:hover {
                color: color(primary-600);
            }
        }
    }

    .word-cloud-vote-list {
        font-size: 12px;
        line-height: 16px;

        &--title,
        &--item-text {
            font-weight: 600;
        }

        &--item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }

        &--show-more {
            margin-top: 12px;

            a {
                display: flex;
                align-items: center;
                font-weight: 600;
                cursor: pointer;

                i {
                    font-size: 18px;
                }
            }
        }

        .expandable.open {
            margin-top: 12px;
        }
    }

    .word-cloud-color-list {
        &--title {
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            margin-bottom: 8px;
        }

        &--item {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        &--sample {
            display: inline-block;
            height: 16px;
            width: 16px;
            margin-right: 8px;
        }
    }

    .poll-view-option, .poll-countdown-view {
        margin-top: box-model(xs);
        .option {
            display: flex;
            line-height: box-model(l);

            .check, .icon-prefix {
                display: flex;
                align-items: center;
                width: box-model(m);
            }

            .ic-check {
                color: color(success-300);
            }

            .ic-x {
                color: color(error-300);
            }

            .name {
                padding-left: box-model(xxs);
            }
        }
    }

    .poll-form {
        .question-input {
            margin: 0 1px;
        }

        .answer {
            .control-inputs {
                display: flex;
                align-items: stretch;
                margin: box-model(xs, 1px);

                .form-group {
                    margin-bottom: 0;
                }

                .answer-input {
                    flex: 1 1 auto;
                    margin-right: box-model(xs);
                }

                button {
                    margin: box-model(0, xs, 0, 0);

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .correct {
                    background-color: color(success-300);
                    color: color(base-000);
                }
            }
        }

        .add-answer {
            text-align: center;
            cursor: pointer;
            padding: box-model(xxs);
            border-radius: $base-border-radius;
            border: 1px dashed color(base-500);
        }

        .answer-limit {
            margin-top: 24px;

            .multiselect {
                width: box-model(s) * 5;

                .multiselect__tags {
                    padding: 0;
                }
            }
        }

        .live-display-targeting {

            label, p.small {
                margin-bottom: box-model(xxs);
            }
            p.small {
                color: color(base-600);
            }

            .multiselect__tags-wrap + strong.multiselect__strong { display: none; }

            .displays-summary {

                @include line-height(m);
                z-index: 1;
                position: absolute;
                background-color: color(base-000);
                top: box-model(xxxs);
                left: box-model(xxxs);
                width: calc(100% - #{box-model(xl) + box-model(xxxs) * 2}); // 100% - (toggler + 2x padding)
                white-space: nowrap;
                overflow: hidden;

                .number-of-displays {
                    @include font-size(xs);
                    @include line-height(document);
                    @include font-weight(semibold);
                    display: inline-block;
                    width: box-model(l);
                    height: box-model(l);
                    background-color: color(base-300);
                    border-radius: box-model(xxs);
                    margin-left: box-model(xxxs);
                    margin-right: box-model(xs);
                    padding: box-model(xxs);
                    text-align: center;
                    transform: translateY(-1px);
                }
            }

            li.multiselect__element:first-child{
                border-bottom: 1px solid color(base-300);
            }

            .item-checkbox {
                display: inline-block;
                margin-right: box-model(xs);
                height: box-model(s);
                width: box-model(s);
                border-radius: box-model(xxs);
                border: 2px solid color(base-300);
                background: color(base-000);
                box-sizing: content-box;
                transform: translateY(2px);

                .ic-check {
                    display: inline-block;
                    font-size: 12px;
                    visibility: hidden;
                    color: color(base-000);
                    transform: translateY(-1px);
                }
            }

            .multiselect__option:hover {
                background-color: color(base-100);
            }

            .multiselect__option--selected {
                background-color: unset;
                color: unset;

                .item-checkbox {
                border: 2px solid color(primary-300);
                background: color(primary-300);

                    .ic-check {
                        visibility: visible;
                    }
                }
            }

            &.disabled {
                cursor: not-allowed;

                .displays-summary {
                    padding-left: box-model(s);
                    background-color: color(base-100);
                }
            }
        }
    }

    .view-poll {
        position: relative;
        overflow: auto;

        .badge {
            width: fit-content;
            margin: box-model(xs) 0;
        }

        .question {
            word-break: normal;
            overflow-wrap: anywhere;
        }

        .answer {
            display: flex;
            align-items: center;
            margin-bottom: box-model(xs);
            padding-left: box-model(xs);
            padding-top: box-model(xs);
            padding-bottom: box-model(xs);
            border: 1px solid color(base-300);
            border-radius: $base-border-radius;
            word-break: break-all;
            background-color: color(base-000);

            .answer-input {
                flex: 1 1 auto;
                margin-right: box-model(xs);
            }

            .answer-percentage {
                white-space: nowrap;
                flex: 0 0 16%;
                text-align: end;
                margin-right: box-model(xs);
            }

            .correct {
                background-color: color(success-300);
                color: color(base-000);
                border-radius: 50%;
                padding: 2px;

                &.ic-check {
                    margin-right: box-model(xs);
                }
            }
        }

        .info {
            cursor: pointer;
            margin-bottom: box-model(m);
            margin-top: - box-model(s);
            display: flex;
            align-items: center;

            &.opened {
                margin-bottom: box-model(xxs);
            }

            a {
                @include font-weight(bold);
                @include font-size(xs);
                color: color(primary-300);
            }

            i {
                color: color(primary-300);
                margin-left: box-model(xxs);
            }
        }

        .info-details {
            @include font-weight(normal);
            @include font-size(s);
            color: color(base-600);
            display: flex;
            flex-direction: column;
            padding-right: box-model(m);
            margin-top: -box-model(m);
        }

        ul.live-displays {
            @include font-size(s);
            margin-top: box-model(xxs);
            margin-bottom: box-model(m);
            margin-left: box-model(l);

            li {
                @include line-height(s);
                @include font-weight(normal);
                list-style-type: disc;
            }
        }
    }

    .smvp-word-cloud-empty-text {
        font-size: 12px;
        font-weight: 600;
    }

    .delete-poll {
        position: absolute;
        top: box-model(m);
        right: box-model(m);

        i.ic-trash-2 {
            margin: 0;
        }
    }

    .poll-form-multiple-choice {
        padding-top: box-model(l);
    }

    .poll-form-word-cloud {
        margin-top: box-model(m);

        .hint {
            color: color(base-500);
            font-size: icon-size(xxs) * 0.75;
        }

        .input-wrapper {
            width: box-model(s) * 5;
        }

        .character-limit {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: box-model(xs, 0);

            .label {
                flex-grow: 1;

                label {
                    @include font-size(base);
                    font-weight: normal;
                    margin: 0;
                }
            }

            .input {
                vertical-align: middle;
            }
        }

        .options {
            padding-left: box-model(xxs) * 0.95;

            .form-group {
                margin-bottom: 0;
            }
        }

        .answer-limit {
            margin: 0;
            padding-left: 24px;
            margin-bottom: 24px;

            .input {
                margin-top: 8px;
            }
        }

        a {
            cursor: pointer;
        }
    }

    .polling-countdown-timer {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        height: auto;
        width: auto;
        background-color: color(base-000);
        border-radius: $base-border-radius;
        border: 1px solid color(base-300);
        padding: 0;

        .countdown-timer-inner {
            padding: box-model(s);
        }

        .countdown-bar {
            height: 4px;
            width: 100%;
            max-width: var(--bar-width);
            background: color(primary-500);
            transition: max-width 1s linear;
            border-radius: $base-border-radius 0 0 0;
        }

        .big-timer {
            height: box-model(xl);
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: font-size(xm);
            line-height: box-model(xl);
            color: color(primary-500);

            i {
                margin-right: box-model(xs);
            }
        }

        .timer-controls {
            display: flex;
            flex-direction: row;
            gap: box-model(m);
            align-items: center;
            justify-content: space-between;
            margin-top: box-model(s);

            .btn {
                margin: 0;
                width: 100%;
            }
        }

        &.blinking .big-timer,
        &.blinking .countdown-bar {
            animation: blink 1s infinite;

            @keyframes blink {
                50% {
                    opacity: 0.5;
                }
            }
        }

        .follow-action-label {
            font-size: font-size(s);
            line-height: box-model(m);
            color: color(base-500);
        }
    }

    .poll-form-targeting {
        margin-top: box-model(xl);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: box-model(xs);
        background: color(base-000);
        border: 1px solid color(base-300);
        border-radius: box-model(xxs);
        cursor: pointer;

        &:hover {
            border-color: color(primary-300);
        }

        .form-element {
            width: 100%;
            height: 100%;
        }

        .targeting-title {
            @include font-size(s);
            @include font-weight(semibold);
            @include line-height(document);
            color: color(base-700);
            display: flex;
            align-items: center;
            margin-bottom: box-model(xs);

            i {
                @include font-size(base);
                margin-right: box-model(xs);
            }
        }

        .visibility-badge {
            margin: 0;
            cursor: default;
        }

        .outer-wrapper {
            height: unset;
        }
    }

    .targeting-description {
        @include line-height(base);

        display: flex;
        margin-top: box-model(xs);

        .icon {
            width: box-model(m);
            display: flex;
            align-items: center;
        }

        .text {
            margin-left: box-model(xxs);
        }
    }

    .targeting-modal {
        .selected-count {
            padding-bottom: box-model(s);
            margin-bottom: box-model(m);
            display: flex;
            align-items: center;

            .count-number {
                padding-left: box-model(xxs);
                padding-right: box-model(xxs);
                border-radius: box-model(xxxs);
                margin: 0;
            }

            .count-text {
                @include font-size(base);
                @include line-height(s);

                margin-left: box-model(xxs);
                color: color(base-700);
            }
        }

        &.apply {
            .selected-count {
                border-bottom: 1px solid color(base-300);
            }
        }
    }
}
