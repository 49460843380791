.template-placeholder {
    border: 1px solid color(base-300);
    border-radius: $base-border-radius;

    .banner, .line {
        background-color: color(base-300);
    }

    .banner {
        min-height: $cards-tile-image-height;
    }

    .line {
        border-radius: $base-border-radius;
        height: line-height-original(xxs);
        margin-bottom: box-model(s);

        &.line-1 {
            max-width: 75%;
        }

        &.line-3 {
            max-width: 50%;
        }

        &.line-4 {
            margin-bottom: box-model(xxxs);
        }
    }

    .fake-content {
        padding: box-model(m);
    }
}
