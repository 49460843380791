.stat-card {
    padding: box-model(xs);

    .title {
        @include line-height(document);
        @include font-size(base);
    }

    .stat {
        @include line-height(base);
        @include font-size(document);
    }
}
