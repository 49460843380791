.fsa-blocks-defs-list button.btn {
    @include font-size(s);
    display: flex;
    margin: 0;
    margin-bottom: -1px;
    padding: 4px 10px;
    border-radius: 0;
    cursor: move;
    background-color: color(base-000);
    text-align: left;
    border: 0;
    border-bottom: 1px solid color(base-300);
}

.fsa-toolbar .fsa-toolbar-main-buttons {
    text-align: left;
}

.content-wrapper.participants {
    .well {
        hr {
            background: transparent;
            border: 0;
            height: box-model(m);
        }

        .bg-export {
            display: block;
            margin-bottom: box-model(xs);
        }
    }

    .action-control-group {
        > span[ng-transclude] > .transcluded {
            display: none;
        }
    }
}

.column-actions .well {
    .bg-export-fp-type.input-block-level {
        display: block !important;
        margin-bottom: box-model(xs) !important;

        .bg-export {
            margin: 0 0 0 !important;
            display: block !important;
        }
    }

    .action-control-group {
        display: block;

        .btn {
            display: block;
            width: 100%;
            margin: 0 0 box-model(xs);
        }
    }
}

.participants {
    .app-activation-code-wrapper {
        padding: box-model(xs, xl);
    }

    .app-activation-code {
        @include secondary-font;
        @include font-size(l);

        text-align: center;
        padding: box-model(m, xl);
        background-color: color(base-100);
        border-radius: $base-border-radius;
    }
}
