.video-library {
    section.stats {
        margin-bottom: box-model(xl);
        display: flex;
        column-gap: box-model(m);

        .separator {
            width: 1px;
            background-color: color(base-300);
        }
    }

    th.thumbnail,
    td.thumbnail {
        width: box-model(xs) * 16;
    }

    td.thumbnail {
        .outer-wrapper {
            position: relative;
            width: box-model(h) * 2;
            border-radius: $base-border-radius;
            overflow: hidden;
            border: 1px solid color(base-300);

            &::before {
                content: '';
                display: block;
                height: 0;
                width: 0;
                padding-bottom: percentage(math.div(9, 16));
            }
        }

        img, .placeholder {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .placeholder {
            color: color(primary-300);
            text-align: center;
            transition: opacity .2s ease-in-out;
            background-color: color(base-000);
            display: flex;
            flex-direction: column;
            justify-content: center;

            span {
                @include font-size(s);
                @include font-weight(bold);
                @include line-height(s);
            }
        }

        i {
            @include font-size(xm);
        }
    }

    td.title {
        @include font-weight(semibold);
    }

    td.filesize {
        max-width: box-model(xxl);
    }
}
